import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private centerSubject = new BehaviorSubject<{latitude: number, longitude: number}>({latitude: 0, longitude: 0});
  private ROIPointSubject = new BehaviorSubject<{ latitude: number, longitude: number } | null>(null);
  private center!: { lat: number; lng: number };

  constructor() {
    this.center = { lat: 0, lng: 0 };
    this.getCenter().subscribe(center => {
      this.center = { lat: center.latitude, lng: center.longitude };
    });
  }

  setCenter(latitude: number, longitude: number) {
    this.centerSubject.next({latitude, longitude});
  }

  getCenter(): Observable<{latitude: number, longitude: number}> {
    console.log('getCenter');
    return this.centerSubject.asObservable();
  }

  setROIPoint(latitude: number, longitude: number) {
    console.log('setROIPoint');
    this.ROIPointSubject.next({ latitude, longitude });
  }

  getROIPoint(): Observable<{ latitude: number, longitude: number } | null> {
    return this.ROIPointSubject.asObservable();
  }

  createROIPoint() {
    this.setROIPoint(this.center.lat, this.center.lng);
  }

  removeROIPoint() {
    this.ROIPointSubject.next(null);
  }

  createROIPointWithCoordinates(coordinates: { latitude: number, longitude: number }) {
    this.setROIPoint(coordinates.latitude, coordinates.longitude);
  }

  
}
