import { OnInit ,Component, ViewChild, Renderer2, ElementRef, AfterViewInit, ViewChildren, QueryList, Input, TemplateRef, ChangeDetectorRef, ViewContainerRef} from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import * as L from 'leaflet';
import parse_georaster from 'georaster';
//import parse_georaster = require('georaster');
import GeoRaster from "georaster";
import GeoRasterLayer from  'georaster-layer-for-leaflet';
//import 'leaflet-draw';
import '@geoman-io/leaflet-geoman-free'
import { AppstoreComponent } from '../appstore/appstore.component';
import { PreferencesComponent } from '../preferences/preferences.component';
import { BandSelectorComponent } from '../band-selector/band-selector.component';
import { HypercubeSelectorComponent } from '../hypercube-selector/hypercube-selector.component';
import { LibraryComponent } from '../library/library.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';
import 'leaflet.polylinemeasure'
import { Treenode } from '../treenode';
import { AuthService } from '../auth.service';
import { MapService } from '../map.service';
import { LoadingService } from '../loading.service';
import { ToastrService } from 'ngx-toastr';
import { Hypercube } from '../hypercube';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import 'leaflet-basemaps';
import { CodeeditorComponent } from '../codeeditor/codeeditor.component';
import { AngularSplitModule, SplitComponent, SplitAreaDirective } from 'angular-split';
import { CommonModule } from '@angular/common';
import { Observable, BehaviorSubject, share, debounceTime, tap, distinctUntilChanged  } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { Subscription, combineLatest } from 'rxjs';
import { filter, max, pairwise  } from 'rxjs/operators';
import chroma from 'chroma-js';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { RendezvousComponent } from '../rendezvous/rendezvous.component';
import { CartComponent } from '../cart/cart.component';
import { CatalogComponent } from '../catalog/catalog.component';
import { WavelengthSelectorComponent } from '../wavelength-selector/wavelength-selector.component';
import { Geometry } from 'geojson';
import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { AdvancedFeaturesService } from '../advanced-features.service';

const iconRetinaUrl = 'assets/marker-icon-2x.png';
const iconUrl = 'assets/marker-icon.png';
const shadowUrl = 'assets/marker-shadow.png';
const iconDefault = L.icon({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});
L.Marker.prototype.options.icon = iconDefault;


var sateliteIcon = L.icon({
  iconUrl: 'assets/images/poi.png',
  //shadowUrl: 'leaf-shadow.png',

  iconSize:     [40, 40]
});

@Component({
  selector: 'app-cartography',
  standalone: true,
  imports: [AppstoreComponent, BandSelectorComponent, LibraryComponent, HypercubeSelectorComponent, PreferencesComponent, CodeeditorComponent, AngularSplitModule, CommonModule, MatIconModule, RendezvousComponent, CartComponent, CatalogComponent, OverlayModule, PortalModule, WavelengthSelectorComponent],
  templateUrl: './cartography.component.html',
  styleUrls: ['./cartography.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class CartographyComponent implements OnInit, AfterViewInit   {
  private map!: L.Map;
  private baseMapLayer!: L.TileLayer;
  private polyMeasure!: L.Control.PolylineMeasure;
  formGroup!: FormGroup;
  @ViewChild('appStore') appStore!: AppstoreComponent;
  @ViewChild('layerLibrary') layerLibrary!: LibraryComponent;
  @ViewChild('codeEditor') codeEditor!: CodeeditorComponent;
  @ViewChild('hypercubeSelector') hypercubeSelector!: HypercubeSelectorComponent;
  @ViewChild('RendezVousList') rendezVousList!: RendezvousComponent;
  @ViewChild('Catalog') catalog!: CatalogComponent;
  @ViewChild('wavelengthselector') wavelenghtselector!: WavelengthSelectorComponent;
  @ViewChild('mapToolbar') toolbarElementRef!: ElementRef;

  @ViewChild(SplitComponent) splitEl!: SplitComponent;
  @ViewChildren(SplitAreaDirective) areasEl!: QueryList<SplitAreaDirective>

  @Input() item = '';

  private apiUrl = environment.apiUrl;
  private tilesUrl = environment.tilesUrl;
  private endPointLayer = 'layer';
  private endPointGeoTiff = 'geotiff';
  private endPointGeoTiff2 = 'geotiff3';
  private endPointSegmentation = 'segmentation';
  private endPointSegmentationPolygon = 'segmentation-polygon';
  private endPointSegmentationMarker = 'segmentation-marker';
  private endPointSegmentationAll = 'segmentation-all';
  private endPointGetRendezVous = 'getrendezvous';
  private defaultR = environment.BAND_DEFAULT_R;
  private defaultG = environment.BAND_DEFAULT_G;
  private defaultB = environment.BAND_DEFAULT_B;
  private mapNode!: Treenode;
  private remoteNodes!: Treenode[];
  hypercubes: Hypercube[] = [];
  currentHypercube!: Hypercube;

  hypercubeMarkers: L.Marker[] = [];
  private textMarkers: L.Marker[] = [];
  //private HypercubeMap = new Map<number, L.Layer[]>();
  private HypercubeMap = new Map<string, L.Layer[]>();

  selectedLayers: Set<L.Layer> = new Set<L.Layer>();
  appStoreVisible = false;
  codeEditorVisible = false;

  private selectedHypercube!: Hypercube;
  bottomAreaSize = 0;
  topAreaSize = 100;

  //isToolbarVisible: boolean = false;
  private toolbarElement: HTMLElement | null = null;
  private isToolbarVisibleSubject = new BehaviorSubject<boolean>(false);
  public isToolbarVisible$: Observable<boolean> = this.isToolbarVisibleSubject.asObservable();

  private selectedItem;
  private toolbarPosition;

  private isDragging = false;
  private startPosition: { x: number, y: number } = { x: 0, y: 0 };

  private logoutSubscription!: Subscription;
  private errorSubscription!: Subscription;
  private hypercubeStatusSubscription!: Subscription;

  displayHypercubeCatalogGroundMark: boolean = true;

  private backgroundImageLayer: L.Layer | null = null;

  private baseMapIsLoading = false;

  public rendezvousMarker!: L.Marker | null;
  private rendezvousFootprint!: L.GeoJSON<any, Geometry> | null;

  @ViewChild('contextMenu') contextMenu!: TemplateRef<any>;
  private overlayRef: OverlayRef | null = null;

  lastRightClickLatLng!: L.LatLng;

  private ROIPointSubscription!: Subscription;

  private segmentsLayer: L.LayerGroup | null = null;
  private segmentsVisibilitySubscription: Subscription | null = null;

  private layerSubscriptions = new Map<string, Subscription[]>();
  private applicationLayers: L.Layer[] = [];
  private isUrbainVisible = false;
  private isVegetationVisible = false;
  private isWaterVisible = false;

  // Ajouter cette propriété à la classe
  private segmentLayers: L.Layer[] = [];

  private removeLayersForCurrentHypercube(hypercube: any) {
    console.log('removeLayersForCurrentHypercube');
    console.log(hypercube);
    if (hypercube && this.map) {
      // Récupérer les calques associés à l'hypercube
      const layers = this.HypercubeMap.get(hypercube.IdImageFile);
      if (layers) {
        // Supprimer chaque calque de la carte
        layers.forEach(layer => {
          if (this.map.hasLayer(layer)) {
            this.map.removeLayer(layer);
          }
        });

        // Nettoyer les souscriptions associées
        const subscriptions = this.layerSubscriptions.get(hypercube.IdImageFile);
        if (subscriptions) {
          subscriptions.forEach(sub => sub.unsubscribe());
          this.layerSubscriptions.delete(hypercube.IdImageFile);
        }

        // Supprimer l'entrée de la Map
        this.HypercubeMap.delete(hypercube.IdImageFile);
      }

      // Nettoyer les marqueurs
      this.hypercubeMarkers.forEach(marker => {
        if (this.map.hasLayer(marker)) {
          this.map.removeLayer(marker);
        }
      });
      this.hypercubeMarkers = [];

      this.textMarkers.forEach(marker => {
        if (this.map.hasLayer(marker)) {
          this.map.removeLayer(marker);
        }
      });
      this.textMarkers = [];

      // Nettoyer les calques sélectionnés
      this.selectedLayers.clear();
      this.layerLibrary.updateChart(this.selectedLayers);
    }
  }

  private removeApplicationLayers() {
    if (this.map) {
      // Supprimer les calques d'applications de la carte
      this.applicationLayers.forEach(layer => {
        if (this.map.hasLayer(layer)) {
          this.map.removeLayer(layer);
        }
      });
      this.applicationLayers = [];

      this.layerLibrary.clearNodes();

      // Réinitialiser les états des boutons
      this.isUrbainVisible = false;
      this.isVegetationVisible = false;
      this.isWaterVisible = false;
    }
  }

  private loadRasterLayer(hypercube: any) {
    console.log('loadRasterLayer');
    console.log(hypercube);
    this.baseMapIsLoading = true;

    // Nettoyer les calques existants
    this.removeLayersForCurrentHypercube(hypercube);
    this.removeApplicationLayers();

    // Mettre à jour l'état de chargement
    this.baseMapIsLoading = true;

    // Nettoyer l'ancienne couche d'image de fond si elle existe
    if (this.backgroundImageLayer) {
      try {
        if (this.map.hasLayer(this.backgroundImageLayer)) {
          this.map.removeLayer(this.backgroundImageLayer);
        }
        this.backgroundImageLayer = null;
      } catch (error) {
        console.error('Error removing background layer:', error);
      }
    }

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  
      },
      credentials: 'include' as RequestCredentials  
    };

    // Ajouter un timeout à la requête
    const timeout = 30000; // 30 secondes
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    fetch(`${this.apiUrl}/${this.endPointGeoTiff2}`, { ...options, signal: controller.signal })
      .then(response => {
        clearTimeout(id);
        if (!response.ok) { 
          this.clearAllNotifications();
          this.toastr.error('Error while loading hypercube', 'Error', {timeOut: 2000, extendedTimeOut:2000});
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.arrayBuffer(); 
      })
      .then(buffer => {
        if(!buffer) {
          throw new Error('No buffer received');
        }
        
        return parse_georaster(buffer);
      })
      .then(georaster => {
        if(!georaster) {
          throw new Error('Failed to parse georaster');
        }

        // Vérifier si la carte existe toujours
        if (!this.map) {
          throw new Error('Map no longer exists');
        }

        const layer = new GeoRasterLayer({
          georaster: georaster,
          opacity: 1,
          resolution: 128,
        });

        this.backgroundImageLayer = layer;

        // Ajouter la couche à la carte
        this.map.addLayer(layer);
        this.darkenBaseMap(50);

        /*if(!this.mapNode) {
          console.log('create map node');
          this.mapNode = this.layerLibrary.createNode(this.translate.instant('BACKGROUND_MAP'), layer, null, "", this.translate.instant('LAYERS'));
          console.log(this.mapNode);
        }*/


        this.mapNode = this.layerLibrary.createNode(this.translate.instant('BACKGROUND_MAP'), layer, null, "", this.translate.instant('LAYERS'));


        console.log('this.authService.CurrentHypercubeSubject.value?.Border?.properties?.coordinates');
        console.log(this.authService.CurrentHypercubeSubject.value?.Border?.properties?.coordinates);
        console.log(this.authService.CurrentHypercubeSubject);
        if(this.authService.CurrentHypercubeSubject.value?.Border?.properties?.coordinates || this.authService.CurrentHypercubeSubject.value?.Border?.properties?.point?.coordinates) {
          this.transitionTo(layer.getBounds());
        }
      })
      .catch(error => {
        console.error('Error:', error);
        this.clearAllNotifications();
        this.toastr.error(error.message || 'Error while loading hypercube', 'Error', {timeOut: 2000, extendedTimeOut:2000});
      })
      .finally(() => {
        clearTimeout(id);
        this.baseMapIsLoading = false;
      });
  }

  constructor(private authService: AuthService, private loadingService: LoadingService, private toastr: ToastrService, private translate: TranslateService, private renderer: Renderer2, public dialog: MatDialog, private mapService: MapService, private overlay: Overlay, private cdr: ChangeDetectorRef, private viewContainerRef: ViewContainerRef, private advancedFeaturesService: AdvancedFeaturesService) { 
    
    
    
    this.authService.CurrentHypercube$.subscribe(value => { 
      if(value)
      {
        this.currentHypercube = value;
        console.log('Current hypercube changed from cartography');
        console.log(this.currentHypercube);
        //this.removeLayersForCurrentHypercube(value.id);
        console.log('1');
        //this.removeLayersForCurrentHypercube(this.currentHypercube);
      }
    });
    
    this.authService.Hypercubes$.subscribe(value => {
      /*if(value)
      {
        value.forEach(hc => {
          console.log(hc.border);
          console.log(hc.border.properties.centre);

          var textTitle = L.divIcon({
              className: 'text-labels', 
              html: hc.name, 
              iconSize: [60, 20] 
          });

          let polygon = L.geoJson(hc.border, {
            style: function(feature) {
              return { 
                 color: '#C7C7C7',
                fillColor: '#C7C7C7', 
                weight: 2, 
                dashArray: '5, 10',
                fillOpacity: 0.2 
            
              }; 
            },
            onEachFeature:(feature, layer) => {
              
              (layer as any).properties = feature.properties;
              let firstPoint;

              if (feature.geometry.type === 'Polygon') {
                  firstPoint = feature.geometry.coordinates[0][0]; 
              } else if (feature.geometry.type === 'MultiPolygon') {
                  firstPoint = feature.geometry.coordinates[0][0][0]; 
              } else {
                  // Gérer les autres types de géométries ou retourner si non pris en charge
                  return;
              }

              let textLatLng = new L.LatLng(firstPoint[1], firstPoint[0]);
      
              // Créer et ajouter le marqueur de texte
              //L.marker(textLatLng, {icon: textTitle}).addTo(this.map);


              layer.on('click', (e) => {
                
                  const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                    width: '300px',
                    data: {
                      title: 'Confirmer chargement de l\'hypercube',
                      content: 'Êtes-vous sûr de vouloir charger l\'hypercube ' + hc.name + ' ? ',
                      noButtonText: 'Non',
                      yesButtonText: 'Oui'
                    }
                  });
                
                  dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                      this.hypercubeSelector.loadHypercube(hc);
                    }
                  });
                  
              });

              layer.on('mouseover', function(e) {
                var info = `
                    <div class="custom-tooltip">
                        <h4>${hc.name}</h4>
                        <p>${hc.country}</p>
                    </div>
                `;
                layer.bindTooltip(info).openTooltip();
              });
      
              layer.on('mouseout', function(e) {
                  // Fermer l'infobulle lorsque la souris sort du polygone
                  layer.closeTooltip();
              });
      
              
            }
          }
          ).addTo(this.map);



          

          var customIcon = L.icon({
            iconUrl: 'data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS41MTQ0IDEuMTI1ODRDMTEuODE2NCAwLjk1ODA1MiAxMi4xODM2IDAuOTU4MDUyIDEyLjQ4NTYgMS4xMjU4NEwyMS40ODQ1IDYuMTI1MjJDMjEuNDkyMSA2LjEyOTQyIDIxLjQ5OTYgNi4xMzM3MiAyMS41MDcxIDYuMTM4MTNDMjEuODEyNSA2LjMxNzgxIDIyIDYuNjQ1NjggMjIgN1YxN0MyMiAxNy4zNjMyIDIxLjgwMzEgMTcuNjk3OCAyMS40ODU2IDE3Ljg3NDJMMTIuNDg1NiAyMi44NzQyQzEyLjE3OTEgMjMuMDQ0NSAxMS44MDU5IDIzLjA0MTYgMTEuNTAyMiAyMi44NjczTDIuNTE0MzYgMTcuODc0QzIuMTk2ODkgMTcuNjk3NyAyIDE3LjM2MzEgMiAxNi45OTk5VjdDMiA2LjY0NTY4IDIuMTg3NDkgNi4zMTc3IDIuNDkyODcgNi4xMzgwMkwyLjUwNzMgNi4xMzc4NEwyLjUxNDM2IDYuMTI1ODRMMTEuNTE0NCAxLjEyNTg0Wk0xMi4wMDAxIDEwLjg1Nkw1LjA1OTIzIDYuOTk5OTVMMTIgMy4xNDM5NkwxOC45NDA5IDdMMTIuMDAwMSAxMC44NTZaTTQgOC42OTk1MVYxNi40MTE1TDExIDIwLjMwMDRWMTIuNTg4NEw0IDguNjk5NTFaTTEzIDEyLjU4ODRWMjAuMzAwNUwyMCAxNi40MTE2VjguNjk5NTFMMTMgMTIuNTg4NFoiIGZpbGw9IiNmZmZmZmYiLz4gPC9nPgoNPC9zdmc+',
            iconSize: [25, 60],
          });  

          let poiMarker = L.marker(hc.border.properties.centre, {
              icon: customIcon,
              title: hc.name 
          }).on('click', (e) => {

              
            const boundsCenter = polygon.getBounds().getCenter();
  
            // Étape 2 : Déplacer la carte vers les nouvelles coordonnées (centre des bornes)
            setTimeout(() => {
              this.map.flyTo(boundsCenter, 10, {
                animate: true,
                duration: 1
              });
            }, 1000);
          
            // Étape 3 : Ajuster le zoom pour afficher entièrement le GeoRasterLayer
            setTimeout(() => {
              this.map.fitBounds(polygon.getBounds(), {
                animate: true,
                duration: 1
              });
              this.clearNotifications('Succès.', 'Hypercube chargé avec succès');
              console.log('2');
              this.removeLayersForCurrentHypercube(this.currentHypercube);
            }, 3000);


          });

          this.hypercubeMarkers.push(poiMarker);
          let HcObj: L.Layer[] = [];
          HcObj.push(polygon);
          HcObj.push(poiMarker);
          console.log('add item to HypercubeMap');
          this.HypercubeMap.set(hc.id, HcObj);

        }
        
        );

        this.updateHypercubePoiDisplay();

      }*/
      if(value /*&& this.displayHypercubeCatalogGroundMark*/)
      {
        value.forEach(hc => {
          console.log(hc.Border);
          console.log(hc.Border.properties);

          var textTitle = L.divIcon({
              className: 'text-labels', 
              html: hc.name, 
              iconSize: [60, 20] 
          });

          let polygon = L.geoJson(hc.Border, {
            style: function(feature) {
              return { 
                 color: '#C7C7C7',
                fillColor: '#C7C7C7', 
                weight: 2, 
                dashArray: '5, 10',
                fillOpacity: 0.2,
                //pane: 'hypercubeMarkerPane'
            
              }; 
            },
            onEachFeature:(feature, layer) => {
              
              (layer as any).properties = feature.properties;
              let firstPoint;

              if (feature.geometry.type === 'Polygon') {
                  firstPoint = feature.geometry.coordinates[0][0]; 
              } else if (feature.geometry.type === 'MultiPolygon') {
                  firstPoint = feature.geometry.coordinates[0][0][0]; 
              } else {
                  // Gérer les autres types de géométries ou retourner si non pris en charge
                  return;
              }

              let textLatLng = new L.LatLng(firstPoint[1], firstPoint[0]);
      
              // Créer et ajouter le marqueur de texte
              //L.marker(textLatLng, {icon: textTitle}).addTo(this.map);


              layer.on('click', (e) => {
                
                  /*const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                    width: '300px',
                    data: {
                      title: 'Confirmer chargement de l\'hypercube',
                      content: 'Êtes-vous sûr de vouloir charger l\'hypercube ' + hc.country + ' ? ',
                      noButtonText: 'Non',
                      yesButtonText: 'Oui'
                    }
                  });*/
                  

                  this.translate.get(['YES', 'NO', 'CONFIRM_HYPERCUBE_LOADING', 'ARE_YOU_SURE_YOU_WANT_TO_LOAD_HYPERCUBE']).subscribe(translations => {
                      let yes = translations['YES'];
                      let no = translations['NO'];
                      let confirm = translations['CONFIRM_HYPERCUBE_LOADING'];
                      let sure = translations['ARE_YOU_SURE_YOU_WANT_TO_LOAD_HYPERCUBE'];

                       let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                        width: '300px',
                        data: {
                          title: confirm,
                          content: sure + hc.country + ' ? ',
                          noButtonText: no,
                          yesButtonText: yes
                        }
                      });

                      dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                          this.hypercubeSelector.loadHypercube(hc);
                        }
                      });

                  });
                  
                
                  /*dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                      this.hypercubeSelector.loadHypercube(hc);
                    }
                  });*/
                  
              });
      
              layer.on('mouseover', function(e) {
                var info = `
                    <div class="custom-tooltip">
                      <h4>${hc.country}</h4>
                        
                    </div>
                `;
                layer.bindTooltip(info).openTooltip();
              });
      
              layer.on('mouseout', function(e) {
                  // Fermer l'infobulle lorsque la souris sort du polygone
                  layer.closeTooltip();
              });
      
              
            }
          }
          ).addTo(this.map);



          

          var customIcon = L.icon({
            iconUrl: 'data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KDTxnIGlkPSJTVkdSZXBvX2JnQ2FycmllciIgc3Ryb2tlLXdpZHRoPSIwIi8+Cg08ZyBpZD0iU1ZHUmVwb190cmFjZXJDYXJyaWVyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KDTxnIGlkPSJTVkdSZXBvX2ljb25DYXJyaWVyIj4gPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS41MTQ0IDEuMTI1ODRDMTEuODE2NCAwLjk1ODA1MiAxMi4xODM2IDAuOTU4MDUyIDEyLjQ4NTYgMS4xMjU4NEwyMS40ODQ1IDYuMTI1MjJDMjEuNDkyMSA2LjEyOTQyIDIxLjQ5OTYgNi4xMzM3MiAyMS41MDcxIDYuMTM4MTNDMjEuODEyNSA2LjMxNzgxIDIyIDYuNjQ1NjggMjIgN1YxN0MyMiAxNy4zNjMyIDIxLjgwMzEgMTcuNjk3OCAyMS40ODU2IDE3Ljg3NDJMMTIuNDg1NiAyMi44NzQyQzEyLjE3OTEgMjMuMDQ0NSAxMS44MDU5IDIzLjA0MTYgMTEuNTAyMiAyMi44NjczTDIuNTE0MzYgMTcuODc0QzIuMTk2ODkgMTcuNjk3NyAyIDE3LjM2MzEgMiAxNi45OTk5VjdDMiA2LjY0NTY4IDIuMTg3NDkgNi4zMTc3IDIuNDkyODcgNi4xMzgwMkwyLjUwNzMgNi4xMzc4NEwyLjUxNDM2IDYuMTI1ODRMMTEuNTE0NCAxLjEyNTg0Wk0xMi4wMDAxIDEwLjg1Nkw1LjA1OTIzIDYuOTk5OTVMMTIgMy4xNDM5NkwxOC45NDA5IDdMMTIuMDAwMSAxMC44NTZaTTQgOC42OTk1MVYxNi40MTE1TDExIDIwLjMwMDRWMTIuNTg4NEw0IDguNjk5NTFaTTEzIDEyLjU4ODRWMjAuMzAwNUwyMCAxNi40MTE2VjguNjk5NTFMMTMgMTIuNTg4NFoiIGZpbGw9IiNmZmZmZmYiLz4gPC9nPgoNPC9zdmc+',
            iconSize: [25, 60],
          });  

          /*let poiMarker = L.marker(hc.border.properties.coordinates, {
              icon: customIcon,
              title: hc.name 
          }).on('click', (e) => {*/
          let poiMarker = L.marker(polygon.getBounds().getCenter(), {
            icon: customIcon,
            title: hc.country 
          }).on('click', (e) => {
              
            const boundsCenter = polygon.getBounds().getCenter();
  
            // Étape 2 : Déplacer la carte vers les nouvelles coordonnées (centre des bornes)
            setTimeout(() => {
              this.map.flyTo(boundsCenter, 10, {
                animate: true,
                duration: 1
              });
            }, 1000);
          
            // Étape 3 : Ajuster le zoom pour afficher entièrement le GeoRasterLayer
            setTimeout(() => {
              this.map.fitBounds(polygon.getBounds(), {
                animate: true,
                duration: 1
              });
              //this.clearNotifications('Succès.', 'Hypercube chargé avec succès');
              this.translate.get(['SUCCESS', 'HYPERCUBE_LOADING_SUCCESS']).subscribe(translations => {
                  let message = translations['HYPERCUBE_LOADING_SUCCESS'];
                  let title = translations['SUCCESS'];
                  this.clearNotifications(title, message);
              });
              console.log('2');
              this.removeLayersForCurrentHypercube(this.currentHypercube);
            }, 3000);


          });

          this.hypercubeMarkers.push(poiMarker);
          let HcObj: L.Layer[] = [];
          HcObj.push(polygon);
          HcObj.push(poiMarker);
          console.log('add item to HypercubeMap');
          this.HypercubeMap.set(hc.id, HcObj);
          console.log(this.HypercubeMap.get(hc.id));
          console.log('Loop on HypercubeMap :');
          this.HypercubeMap.forEach((value: L.Layer[], key: string) => {
            console.log(key, value);
          });
        }
        
        );

        this.updateHypercubePoiDisplay();

      }
    })
  }

  hexToRgb(hex: string): [number, number, number] {
    // Supprimer le symbole dièse (#) s'il est présent
    let cleanedHex = hex.charAt(0) === '#' ? hex.substring(1) : hex;
  
    // Gérer les formats hexadécimaux courts (par exemple, #f03)
    if (cleanedHex.length === 3) {
      cleanedHex = cleanedHex.split('').map(char => char + char).join('');
    }
  
    // Convertir l'hexadécimal en valeurs RGB
    const bigint = parseInt(cleanedHex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
  
    return [r, g, b];
  }

  onToolbarDragStart(event: MouseEvent): void {

    if (this.toolbarPosition) {
      const position = JSON.parse(this.toolbarPosition);
    }

    this.isDragging = true;

    console.log(this.toolbarElement);
    if(this.toolbarElement)
    {
      this.startPosition = {
          x: event.clientX - this.toolbarElement.getBoundingClientRect().left,
          y: event.clientY - this.toolbarElement.getBoundingClientRect().top
      };

      document.addEventListener('mousemove', this.onToolbarDragMove);
      document.addEventListener('mouseup', this.onToolbarDragEnd);
    }
  }

  onToolbarDragMove = (event: MouseEvent): void => {
      console.log('isDragging');
      console.log(this.isDragging);
      console.log('this.toolbarElement');
      console.log(this.toolbarElement);
      if (!this.isDragging || !this.toolbarElement) return;

      const x = event.clientX - this.startPosition.x;
      const y = event.clientY - this.startPosition.y;

      this.toolbarElement.style.left = `${x}px`;
      this.toolbarElement.style.top = `${y}px`;

      console.log('${y}px');
      console.log(`${y}px`);
      console.log('this.toolbarElement.style.top');
      console.log(this.toolbarElement.style.top);
      console.log(this.toolbarElement);
  };

  onToolbarDragEnd = (): void => {
      console.log('onToolbarDragEnd');
      this.isDragging = false;
      document.removeEventListener('mousemove', this.onToolbarDragMove);
      document.removeEventListener('mouseup', this.onToolbarDragEnd);
      if(this.toolbarElement){
        const rect = this.toolbarElement.getBoundingClientRect();
        this.toolbarPosition = JSON.stringify({ left: rect.left, top: rect.top })
        console.log('this.toolbarPosition');
        console.log(this.toolbarPosition);
      }

  };

  hideToolbar() {
    this.isToolbarVisibleSubject.next(false);
    this.selectedItem = null;
  }

  deleteSelectedObject() {
    if (this.selectedItem) {
        this.map.removeLayer(this.selectedItem);
    }
  }

  moveSelectedObject() {
    if(this.selectedItem)
    {
      console.log(this.selectedItem.isDraggingEnabled);
    }
    if (this.selectedItem && this.selectedItem.dragging && this.selectedItem instanceof L.Marker) {
      
      this.selectedItem.dragging.enable();
      (this.selectedItem as any).isDraggingEnabled = true;
      
    }
    if(this.selectedItem)
    {
      console.log(this.selectedItem.isDraggingEnabled);
    }
  }

  onDragEnd(event : any) {
    console.log(event);
    this.bottomAreaSize = event.sizes[1];
    this.topAreaSize = event.sizes[0];

    console.log(' onDragEnd bottomAreaSize');
    console.log(this.bottomAreaSize);
    console.log(' onDragEnd topAreaSize');
    console.log(this.topAreaSize);
    if (this.map) {
      this.map.invalidateSize();
    }
  }

  handleAppStoreMapClicked(arg: any): void {
    const customButton = document.querySelector('.action-appstore') as HTMLElement;
    if (customButton) {
        customButton.click() ;
    }
  }

  handleHypercubeSelectorMapClicked(arg: any): void {
    const customButton = document.querySelector('.action-hypercubeSelector') as HTMLElement;
    if (customButton) {
        customButton.click();
    }
  }

  handleDisplayMarkersChange(arg: any): void {
    console.log('handleDisplayMarkersChange');
    console.log(arg);
    this.displayHypercubeCatalogGroundMark = arg;
    //this.updateHypercubePoiDisplay();

    if(this.displayHypercubeCatalogGroundMark)
    {
      //this.updateHypercubePoiDisplay();
      var currentZoom = this.map.getZoom();

      console.log('Loop 3 on HypercubeMap :');
      this.HypercubeMap.forEach((value: L.Layer[], key: string) => {
        console.log('current hypercube id : ' + this.currentHypercube.IdImageFile);
        console.log('loop hypercube id : ' + key);
        if(this.currentHypercube.IdImageFile == key)
        {
          //console.log('currenthypercube and key are the same');
          this.map.addLayer(value[0]);

          if (currentZoom < 10) {
            if (!this.map.hasLayer(value[1])) 
            {
              this.map.addLayer(value[1]);
            }
          }

          if (this.map && this.backgroundImageLayer && this.map.hasLayer(this.backgroundImageLayer)) {
            this.map.removeLayer(this.backgroundImageLayer);
            this.map.addLayer(this.backgroundImageLayer);
          }
        }

      });
    }
    else
    {
      this.removeAllHypercubeMarkers();
    }
  }

  removeAllHypercubeMarkers() {
    console.log('remove all hypercube markers');
    /*this.hypercubeMarkers.forEach(marker => {
      console.log(marker[0]);
      console.log(marker[1]);
      if(this.map.hasLayer(marker)){
        this.map.removeLayer(marker);
      }
    });*/
    console.log('Loop 2 on HypercubeMap :');
    this.HypercubeMap.forEach((value: L.Layer[], key: string) => {
      this.map.removeLayer(value[0]);
      this.map.removeLayer(value[1]);
    });
  }

  handleButtonClick(app: any): void {
    console.log('handleButtonClick');
    console.log(app);
    var extraParameters = '';

    const customButton = document.querySelector('.action-appstore') as HTMLElement;
    if (customButton) {
        customButton.click() ;
    }

    let message = this.translate.instant('APPLICATION_LOADING');
    let title = this.translate.instant('PLEASE_WAIT');
    this.translate.get(['APPLICATION_LOADING', 'PLEASE_WAIT']).subscribe(translations => {
        let message = translations['APPLICATION_LOADING'];
        let title = translations['PLEASE_WAIT'];
        this.toastr.info(message, title);
    });

    this.toastr.info(message, title);

    if(app.selectedValue) {
      extraParameters = `&${app.parametername}=${app.selectedValue}` 
    }

    if(app.id > 8) {
      extraParameters = `&${app.parametername}=${app.parameterval}` + `&${app.parametername2}=${app.parameterval2}`;
    }

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  
      },
      credentials: 'include' as RequestCredentials 
    };

    console.log('fetching ' + `${this.apiUrl}/${this.endPointLayer}?id=${app.id}${extraParameters}`);
    fetch(`${this.apiUrl}/${this.endPointLayer}?id=${app.id}${extraParameters}`, options)
    .then(response => {
      console.log('response : ');
      console.log(response);
      
      if (!response.ok) {
        console.log('response not ok');
        
        // Gestion spécifique selon le code d'erreur
        if (response.status === 404) {
          // Pour le code 404, afficher une notification toastr
          this.clearAllNotifications();
          this.toastr.info('', 'Aucune données trouvées');
        } else if (response.status === 500) {
          // Pour le code 500, afficher la boîte de dialogue
          this.clearAllNotifications();
          const dialogRef = this.dialog.open(InfoDialogComponent, {
            width: '400px',
            data: {
              title: 'Attention',
              content: 'App can\'t run without a loaded Hypercube',
              noButtonText: 'OK'
            }
          });
        } else {
          // Pour les autres codes d'erreur, afficher une notification toastr
          this.toastr.error('Une erreur est survenue lors du chargement', 'Erreur', {
            timeOut: 3000,
            positionClass: 'toast-top-right'
          });
        }
        
        throw new Error('Server error while loading app');
      } else {
        return response.arrayBuffer();
      }
    })  
      .then(buffer => {
        console.log(buffer);
        console.log(app.id);
        if(app.id == 8) {
          let minValue = Infinity;
          let maxValue = -Infinity;

          parse_georaster(buffer, { projection: 4326 }).then(georaster => {
            console.log("georaster:", georaster);

            let values: number[] = [];
            for (let bandIndex = 0; bandIndex < georaster.numberOfRasters; bandIndex++) {
              const band = georaster.values[bandIndex];
              band.forEach(row => {
                  row.forEach(value => {
                      if (!isNaN(value)) {
                          values.push(value);
                      }
                  });
              });
            }

            const minValue = values[Math.floor(0.2 * values.length)];
            const maxValue = values[Math.floor(0.8 * values.length)];

            var layer = new GeoRasterLayer({
              georaster: georaster,
              resolution: 128,
              pane: 'appLayerPane',
              pixelValuesToColorFn: function(values) {
                let pixelValue = values[0];

                if (isNaN(pixelValue)) {
                  return 'rgba(0,0,0,0)'; 
                }

                if(pixelValue < minValue) {
                  pixelValue = minValue;
                }
                else if(pixelValue > maxValue) {
                  pixelValue = maxValue;
                }

                const normalizedValue = 2 * ((pixelValue - minValue) / (maxValue - minValue)) - 1;
                const colorScale = chroma.scale('RdYlBu').domain([1, -1]);

                return colorScale(normalizedValue).hex();
              }
            });

            this.map.addLayer(layer);
            layer.setZIndex(10001);
            this.applicationLayers.push(layer);

            this.mapNode = this.layerLibrary.createNode(this.translate.instant(app.name), layer, null, "", this.translate.instant('LAYERS'));
            
            this.clearNotifications('Succès.', 'Calque chargé avec succès');
          });
        }
        else if(app.id > 8) {
          parse_georaster(buffer).then(georaster => {
            console.log("georaster:", georaster);

            var gradientStops = [
              { value: -1, color: [0, 0, 139] },      
              { value: 0, color: [255, 192, 203]},    
              { value: 1, color: app.color }        
            ];

            
            var layer = new GeoRasterLayer({
              georaster: georaster,
              opacity: 1,
              resolution: 128,
              /*pane: 'appLayerPane',
                  pixelValuesToColorFn: function(values) {
                      var value = values[0];
    
                      if (isNaN(value)) {
                        return 'rgba(0,0,0,0)';
                      }
              
    
                      var startStop, endStop;
                      for (let i = 0; i < gradientStops.length - 1; i++) {
                          if (value >= gradientStops[i].value && value <= gradientStops[i + 1].value) {
                              startStop = gradientStops[i];
                              endStop = gradientStops[i + 1];
                              break;
                          }
                      }
    
                      var t = (value - startStop.value) / (endStop.value - startStop.value);
                      var r = startStop.color[0] + t * (endStop.color[0] - startStop.color[0]);
                      var g = startStop.color[1] + t * (endStop.color[1] - startStop.color[1]);
                      var b = startStop.color[2] + t * (endStop.color[2] - startStop.color[2]);
              
                      return `rgb(${Math.round(r)},${Math.round(g)},${Math.round(b)})`;
                  }*/

            });

            console.log('layer : ');
            console.log(layer);
            this.map.addLayer(layer);
            layer.setZIndex(10001);
            this.applicationLayers.push(layer);

            this.mapNode = this.layerLibrary.createNode(this.translate.instant(app.name), layer, null, "", this.translate.instant('LAYERS'));
            
            this.clearNotifications('Succès.', 'Calque chargé avec succès');
          });
        }
      });
  }

  handleBandButtonClick(bands: any): void {
    console.log('From carto');
    console.log(bands);
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  
      },
      credentials: 'include' as RequestCredentials 
    };
    /*
    const r = bands[0].wavelength.slice(0, -3);
    const g = bands[1].wavelength.slice(0, -3);
    const b = bands[2].wavelength.slice(0, -3);
    */
    const r = bands[0];
    const g = bands[1];
    const b = bands[2];
    
    //fetch(`${this.apiUrl}/${this.endPointGeoTiff2}?r=${r}&g=${g}&b=${b}`, options)
    this.loadingService.showLoadingBands();
    
    fetch(`${this.apiUrl}/update_RGB?r=${r}&g=${g}&b=${b}`, options)
      .then(response => response.arrayBuffer())
      .then(buffer => {
        console.log(buffer);

        parse_georaster(buffer).then(georaster => {
          console.log("georaster:", georaster);

          var layer = new GeoRasterLayer({
              georaster: georaster,
              opacity: 1,
              resolution: 128

          });
          console.log('mapNode');
          console.log(this.mapNode);
          if(this.mapNode)
            this.layerLibrary.deleteNode(this.mapNode);
          this.map.addLayer(layer);
          //this.mapNode = this.layerLibrary.createNode("Carte de fond", layer, "", "Calques");
          this.mapNode = this.layerLibrary.createNode(this.translate.instant('BACKGROUND_MAP'), layer, "", this.translate.instant('LAYERS'));

          this.loadingService.hideLoadingBands();
          //this.toastr.success('Bandes chargées', 'Succès');

          this.removeLayersForCurrentHypercube(this.currentHypercube);
          this.toastr.success(this.translate.instant('BANDS_LOADED'), this.translate.instant('SUCCESS'));
        });
      });

  }


  layerVisibilityChanged(node: any): void {
    console.log("log from cartography " + node );
    if(node.visible){
      this.map.addLayer(node.object);
      if(node.name == "Carte de fond")
      {
        node.object.setZIndex(10001);
        this.darkenBaseMap(50);
      }
      else
      {
        console.log('Layervisibility changed');
        console.log(node.object);
        if (node.object instanceof L.Layer)
        {

        }
        else
        {
          node.object.setZIndex(10002);
        }
      }
    }
    else{
      this.removeLayer(node.object);
      if(node.name == "Carte de fond")
      {
        this.darkenBaseMap(100);
      }
      
    }
  }

  nodeDeleted(node: any): void {
    console.log("Node Deleted from Cartography");
    this.removeLayer(node.object);
    this.saveTreeNodes();
  }

  hypercubeChanged(hypercube: any): void {
    console.log("hypercube changed from Cartography");
    console.log(this.authService.CurrentHypercubeSubject.value);
    console.log(this.authService.hypercubeStatusSubject.value);
    if(this.authService.CurrentHypercubeSubject.value && this.authService.hypercubeStatusSubject.value === 'ok')
    {
      console.log('hypercubeChanged : CHANGING HYPERCUBE');
      this.darkenBaseMap(100);
      this.resetMapPosition();
    }
    else
    {
      console.log('hypercubeChanged : HYPERCUBE FROM SCRATCH');
    }

    //this.transitionTo(this.authService.CurrentHypercubeSubject.value.latitude, this.authService.CurrentHypercubeSubject.value.longitude, 10);
    if(this.hypercubeSelector.isExpanded){
      //this.hypercubeSelector.toggleView();
      this.handleHypercubeSelectorMapClicked(null);
    }
    
    //this.toastr.info('Chargement de l\'hypercube', 'Veuillez patienter');
    this.toastr.info(this.translate.instant('HYPERCUBE_LOADING'), this.translate.instant('PLEASE_WAIT'));
    console.log(hypercube);
    console.log('this.mapNode');
    console.log(this.mapNode);
    if(this.mapNode)
    {
      this.nodeDeleted(this.mapNode);
      this.layerLibrary.deleteNode(this.mapNode);
    }
    //console.log('transitionTo from hypercubeChanged');
    //this.transitionTo(this.authService.CurrentHypercubeSubject.value.latitude, this.authService.CurrentHypercubeSubject.value.longitude, 10);
    //this.transitionTo(hypercube.latitude, hypercube.longitude, 16);
    //this.loadRasterLayer(hypercube);
  }

  /*public transitionTo(newLat: number, newLng: number, newZoom: number): void {
    const currentZoom = this.map.getZoom();

    // Étape 1 : Dézoomer
    this.map.flyTo([this.map.getCenter().lat, this.map.getCenter().lng], 4, {
      animate: true,
      duration: 1,
    });

    // Étape 2 : Déplacer la carte vers les nouvelles coordonnées
    setTimeout(() => {
        this.map.flyTo([newLat, newLng], 10, {
        animate: true,
        duration: 2
      });
    }, 1000);

    // Étape 3 : Rezoomer vers le nouveau niveau de zoom
    setTimeout(() => {
      this.map.flyTo([newLat, newLng], newZoom, {
        animate: true,
        duration: 1
      });
    }, 3000);
  }*/

  public transitionTo(rasterBounds: L.LatLngBounds): void {
    console.log('transitionTo');
    console.log(rasterBounds);
    // Étape 1 : Dézoomer
    if(this.map && this.map.getCenter())
    {
      this.map.flyTo(this.map.getCenter(), 4, {
        animate: true,
        duration: 1
      });
    }
  
    // Calcul du centre des bornes du GeoRasterLayer
    const boundsCenter = rasterBounds.getCenter();
  
    // Étape 2 : Déplacer la carte vers les nouvelles coordonnées (centre des bornes)
    setTimeout(() => {
      this.map.flyTo(boundsCenter, 10, {
        animate: true,
        duration: 2
      });
    }, 1000);
  
    // Étape 3 : Ajuster le zoom pour afficher entièrement le GeoRasterLayer
    setTimeout(() => {
      this.map.fitBounds(rasterBounds, {
        animate: true,
        duration: 1
      });
      //this.clearNotifications('Succès.', 'Hypercube chargé avec succès');
      this.translate.get(['SUCCESS', 'HYPERCUBE_LOADING_SUCCESS']).subscribe(translations => {
          let message = translations['HYPERCUBE_LOADING_SUCCESS'];
          let title = translations['SUCCESS'];
          this.clearNotifications(title, message);
      });
      console.log('3');
      this.removeLayersForCurrentHypercube(this.currentHypercube);
    }, 3000);

    
  }

  public resetMapPosition()
  {
    this.map.flyTo([43.635174, 1.398204], 3, {
      animate: true,
      duration: 1
    });
  }


  calculate(): void {
    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  // Assurez-vous que le préfixe "Bearer " est correct.
      },
      credentials: 'include' as RequestCredentials  // Important si vous utilisez des sessions ou des cookies.
    };
    
    const r = this.defaultR;
    const g = this.defaultG;
    const b = this.defaultB;
    fetch(`${this.apiUrl}/calculate_spectral_index`, options)
    //fetch(`${this.apiUrl}/${this.endPointGeoTiff}`, options)
      .then(response => response.arrayBuffer())
      .then(buffer => {
        console.log(buffer);

        parse_georaster(buffer).then(georaster => {
          console.log("georaster:", georaster);

          var gradientStops = [
            { value: -1, color: [0, 0, 139] },      // Bleu foncé (DarkBlue)
            { value: 0, color: [255, 192, 203]},    // Rouge clair (LightPink) - même valeur que bleu clair, mais différente couleur
            { value: 1, color: [139, 0, 0] }        // Rouge foncé (DarkRed)
        ];
        
          var layer = new GeoRasterLayer({
              georaster: georaster,
              opacity: 1,
              resolution: 128,
              pixelValuesToColorFn: function(values) {
                  var value = values[0];

                  if (isNaN(value)) {
                    return 'rgba(0,0,0,0)';
                  }
          

                  var startStop, endStop;
                  for (let i = 0; i < gradientStops.length - 1; i++) {
                      if (value >= gradientStops[i].value && value <= gradientStops[i + 1].value) {
                          startStop = gradientStops[i];
                          endStop = gradientStops[i + 1];
                          break;
                      }
                  }

                  var t = (value - startStop.value) / (endStop.value - startStop.value);
                  var r = startStop.color[0] + t * (endStop.color[0] - startStop.color[0]);
                  var g = startStop.color[1] + t * (endStop.color[1] - startStop.color[1]);
                  var b = startStop.color[2] + t * (endStop.color[2] - startStop.color[2]);
          
                  return `rgb(${Math.round(r)},${Math.round(g)},${Math.round(b)})`;

                  /*var value = values[0];

                  if (value < 0){
                    return 'rgb(0, 0, 139)'; 
                  }
                  if (value > 0){
                    return 'rgb(139, 0, 0)'; 
                  }
                  else{
                    return 'rgba(0,0,0,0)';
                  }*/
              }

          });
          //layerControl.addOverlay(layer, label);
          this.map.addLayer(layer);
          layer.setZIndex(10001);
          //this.mapNode = this.layerLibrary.createNode("NDVI", layer, null, "", "Calques");
          this.mapNode = this.layerLibrary.createNode("NDVI", layer, null, "", this.translate.instant('LAYERS'));
          
      });


    });
  }

  nodeSelected(node: any): void {
    console.log('nodeSelected');
    //node.object.fireEvent('click');
    if (this.selectedLayers.has(node.object)) {
      // Désélectionner le layer
      //node.object.setStyle({ color: 'blue' }); // Ou votre style de layer par défaut
      if (typeof node.object.setStyle === 'function') {
        node.object.setStyle({ color: 'rgb(51, 136, 255)', fill:'rgb(51, 136, 255)'  });
      }
      this.selectedLayers.delete(node.object);
      this.layerLibrary.updateChart(this.selectedLayers);
    } 
    // Si le layer n'est pas sélectionné
    else {
      // Sélectionner le layer
      this.selectedLayers.add(node.object);
      if (typeof node.object.setStyle === 'function') {
        node.object.setStyle({ color: 'green' }); 
      }
      this.layerLibrary.updateChart(this.selectedLayers);
    }
  }

  nodeEdited(node: any): void {
    //this.removeLayer(node.object);
    this.saveTreeNodes();
  }

  /*private loadRasterLayer(hypercube: Hypercube): void {
    if (!this.map || !hypercube) {
      this.toastr.error('Map or hypercube not initialized', 'Error');
      return;
    }

    // Nettoyer les anciennes couches
    this.removeLayersForCurrentHypercube(hypercube);
    
    // Mettre à jour l'état de chargement
    this.baseMapIsLoading = true;

    // Nettoyer l'ancienne couche d'image de fond si elle existe
    if (this.backgroundImageLayer) {
      try {
        if (this.map.hasLayer(this.backgroundImageLayer)) {
          this.map.removeLayer(this.backgroundImageLayer);
        }
        this.backgroundImageLayer = null;
      } catch (error) {
        console.error('Error removing background layer:', error);
      }
    }

    const options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  
      },
      credentials: 'include' as RequestCredentials  
    };

    // Ajouter un timeout à la requête
    const timeout = 30000; // 30 secondes
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    fetch(`${this.apiUrl}/${this.endPointGeoTiff2}`, { ...options, signal: controller.signal })
      .then(response => {
        clearTimeout(id);
        if (!response.ok) { 
          this.clearAllNotifications();
          this.toastr.error('Error while loading hypercube', 'Error', {timeOut: 2000, extendedTimeOut:2000});
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.arrayBuffer(); 
      })
      .then(buffer => {
        if(!buffer) {
          throw new Error('No buffer received');
        }
        
        return parse_georaster(buffer);
      })
      .then(georaster => {
        if(!georaster) {
          throw new Error('Failed to parse georaster');
        }

        // Vérifier si la carte existe toujours
        if (!this.map) {
          throw new Error('Map no longer exists');
        }

        const layer = new GeoRasterLayer({
          georaster: georaster,
          opacity: 1,
          resolution: 128,
        });

        this.backgroundImageLayer = layer;

        // Ajouter la couche à la carte
        this.map.addLayer(layer);
        this.darkenBaseMap(50);

        if(!this.mapNode) {
          this.mapNode = this.layerLibrary.createNode(this.translate.instant('BACKGROUND_MAP'), layer, null, "", this.translate.instant('LAYERS'));
        }

        if(this.authService.CurrentHypercubeSubject.value?.Border?.properties?.coordinates) {
          this.transitionTo(layer.getBounds());
        }
      })
      .catch(error => {
        console.error('Error:', error);
        this.clearAllNotifications();
        this.toastr.error(error.message || 'Error while loading hypercube', 'Error', {timeOut: 2000, extendedTimeOut:2000});
      })
      .finally(() => {
        clearTimeout(id);
        this.baseMapIsLoading = false;
      });
  }*/

  openAppStore() {
    console.log('open appstore button');
    this.appStore.toggleAppStore();
  }

  public onAppStoreVisibleChange(visible: boolean) {
    console.log('onAppStoreVisibleChange');
    //this.appStoreVisible = visible;
    //this.appStore.toggleAppStore();
  }

  updateRendezVousList(latitude: number, longitude: number): void {

    if(this.rendezvousFootprint)
    {
      this.rendezvousFootprint.removeFrom(this.map);
      this.rendezvousFootprint = null;
    }
    this.rendezVousList.setLoading(true);

    let parameters = {
      lat: latitude,
      long: longitude
    };

  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token') 
    },
    credentials: 'include' as RequestCredentials 
  };

  fetch(`${this.apiUrl}/${this.endPointGetRendezVous}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      credentials: 'include' as RequestCredentials,
      body: JSON.stringify(parameters),
      })
    .then(response => response.json())
    .then((data) => {
      console.log('backend response ' + JSON.stringify(data));
      this.rendezVousList.addDataFromJson(JSON.stringify(data));



      //this.rendezVousList.toggleRendezVous();
    })
    .catch((error) => {
        console.error('Error:', error);
    });
  }


  onROIPointChange(coordinates: {latitude: number, longitude: number} | null) {
    console.log('onROIPointChanged');
    console.log(coordinates);
    if(coordinates){
      if(!this.rendezvousMarker) 
      {
        this.rendezvousMarker = L.marker([coordinates.latitude, coordinates.longitude], {icon: sateliteIcon, draggable: true});
        this.rendezvousMarker.on('dragend', (e) => {
          var newCoords = e.target.getLatLng();
          //this.updateRendezVousList(newCoords.lat, newCoords.lng);
          this.mapService.setROIPoint(newCoords.lat, newCoords.lng);
          console.log(`Nouvelles coordonnées : ${newCoords.lat}, ${newCoords.lng}`);
        });
        this.rendezvousMarker.addTo(this.map);
        this.rendezvousMarker.on('click', (e) => {

          if(this.rendezvousMarker)
          {
            this.map.flyTo(this.rendezvousMarker.getLatLng(), 12, {
              animate: true,
              duration: 1
            });
          }

        });
        //this.updateRendezVousList(coordinates.latitude, coordinates.longitude);
      }
    }
    else
    {
      if(this.rendezvousMarker)
      {
        this.map.removeLayer(this.rendezvousMarker);
        this.rendezvousMarker = null;
      }
    }
    if(this.rendezvousFootprint)
    {
      this.rendezvousFootprint.removeFrom(this.map);
      this.rendezvousFootprint = null;
    }
  }

  /*onNewRendezVousGeoPoint(coordinates : {latitude: number, longitude: number} | null){
    console.log('onNewRendezVousGeoPoint');
    if(coordinates){
      
      this.rendezvousMarker = L.marker([coordinates.latitude, coordinates.longitude], {icon: sateliteIcon, draggable: true});
      this.rendezvousMarker.on('dragend', (e) => {
        var newCoords = e.target.getLatLng(); 
        this.updateRendezVousList(newCoords.lat, newCoords.lng);
        console.log(`Nouvelles coordonnées : ${newCoords.lat}, ${newCoords.lng}`);
      });
      this.rendezvousMarker.addTo(this.map);
      this.rendezvousMarker.on('click', (e) => {

        this.map.flyTo(this.rendezvousMarker.getLatLng(), 12, {
          animate: true,
          duration: 1
        });

      });
      this.updateRendezVousList(coordinates.latitude, coordinates.longitude);

    }
    else
    {
      if(this.rendezvousMarker)
      {
        this.map.removeLayer(this.rendezvousMarker);
      }
    }
    if(this.rendezvousFootprint)
    {
      this.rendezvousFootprint.removeFrom(this.map);
      this.rendezvousFootprint = null;
    }
  }*/

  onNewRendezVousFootprint(footprint: string |null){
    console.log('onNewRendezVousFootprint');
    console.log(footprint);
    if(this.rendezvousFootprint)
    {
      this.rendezvousFootprint.removeFrom(this.map);
      this.rendezvousFootprint = null;
    }
    if(footprint)
    {
      this.rendezvousFootprint = L.geoJSON(JSON.parse(footprint));
      console.log('ASSIGN rendezvousFootprint' );
      console.log(this.rendezvousFootprint);
      this.rendezvousFootprint.addTo(this.map);
    }
  }

  openLibrary() {
    this.layerLibrary.togglePanel();
  }

  openRendezvousList(){
    this.rendezVousList.toggleRendezVous();
  }

  openCatalog(){
    this.catalog.toggleCatalog();
  }

  onRendezVousVisibleChange(visible: boolean) {
    console.log('onRendezVousVisibleChange');
  }

  openTerminal() {

    //this.codeEditor.toggleCodeEditor();
    //this.codeEditorVisible = this.codeEditor.isVisible();
    //this.bottomAreaSize = this.bottomAreaSize === 100 ? 0 : 50;
    //this.map.invalidateSize();
    console.log('bottomAreaSize');
    console.log(this.bottomAreaSize);
    //this.areasEl.first.collapse();
    //console.log(this.areasEl.last);
    if(this.bottomAreaSize === 0)
    {
      this.bottomAreaSize = 30;
      this.topAreaSize = 70;
      this.map.invalidateSize();
    }
    else
    {
      //this.bottomAreaSize = 0;
      //this.topAreaSize = 100;

      //this.map.invalidateSize();
    }
    //this.onDragEnd();
    console.log('bottomAreaSize');
    console.log(this.bottomAreaSize);
    console.log(this.topAreaSize);
    //this.areasEl.last.expand();
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit');
    

    setTimeout(() => {
      this.toolbarElement = this.toolbarElementRef.nativeElement;
        console.log('TOOLBARELEMENT INITIATED');
    });

    this.appStoreVisible = this.appStore.showAppStore;

    //this.layerLibrary.createNode("Calques", new Object, new Object, "layers");
    //this.layerLibrary.createNode(this.translate.instant('LAYERS'), new Object, new Object, "layers");
    //this.layerLibrary.createNode("Segmentations", new Object, new Object, "content_cut");
    this.translate.get(['LAYERS', 'SEGMENTATIONS']).subscribe(translations => {
      this.layerLibrary.createNode(translations['LAYERS'], new Object, new Object, "layers");
      this.layerLibrary.createNode(translations['SEGMENTATIONS'], new Object, new Object, "content_cut");
    });


    
    //this.layerLibrary.createNode(this.translate.instant('SEGMENTATIONS'), new Object, new Object, "content_cut");

    this.authService.Nodes$.subscribe(value => this.importTreeNodes(value) );



/*
    this.authService.hypercubeStatus$.subscribe(value => {
      if(value === 'ok')
      {
        console.log('clearing notifications');
        //this.clearNotifications();

        this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);

        if(!this.mapNode )
        {
          console.log('Carto : mapNode null 1');
          //this.transitionTo(this.authService.CurrentHypercubeSubject.value.latitude, this.authService.CurrentHypercubeSubject.value.longitude, 16);
          //this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);
          
          //this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);
          //this.transitionTo(this.authService.CurrentHypercubeSubject.value.latitude, this.authService.CurrentHypercubeSubject.value.longitude, 16);

        }
      }
    });
*/

/*
    combineLatest([this.authService.hypercubeStatusSubject, this.authService.CurrentHypercubeSubject]).pipe(
      filter(([val1, val2]) => val1 === 'ok' && val2 !== null)
    ).subscribe(([val1, val2]) => {
      console.log("COMBINE LATEST EXECUTED : ");
      console.log("this.authService.hypercubeStatusSubject : " + val1);
      console.log("this.authService.CurrentHypercubeSubject : " + val2);
      this.toastr.info(this.translate.instant('HYPERCUBE_LOADING'), this.translate.instant('PLEASE_WAIT'));
      this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);
    });
*/
/*    combineLatest([this.authService.hypercubeStatusSubject, this.authService.CurrentHypercubeSubject]).pipe(
      filter(([val1]) => val1 === 'ok')
    ).subscribe(([val1, val2]) => {
      console.log("COMBINE LATEST EXECUTED : ");
      console.log("this.authService.hypercubeStatusSubject : " + val1);
      console.log("this.authService.CurrentHypercubeSubject : " + val2);
      if(val2 !== null)
      {
        this.toastr.info(this.translate.instant('HYPERCUBE_LOADING'), this.translate.instant('PLEASE_WAIT'));
        this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);
      }
    });*/

    /*this.authService.hypercubeStatusSubject.pipe(
      pairwise(),
      filter(([previousValue, currentValue]) => previousValue === 'loading' && currentValue === 'ok')
    ).subscribe(() => {

      if(!this.baseMapIsLoading)
      {
        console.log("La valeur est passée de 'loading' à 'ok'", 'Timestamp:', new Date().toISOString());
        if(this.authService.CurrentHypercubeSubject.value !== null)
        {
          this.toastr.info(this.translate.instant('HYPERCUBE_LOADING'), this.translate.instant('PLEASE_WAIT'));
          this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);
        }
      }
    });*/
    this.hypercubeStatusSubscription = this.authService.hypercubeStatusSubject.pipe(
      pairwise(),
      filter(([previousValue, currentValue]) => previousValue === 'loading' && currentValue === 'ok'),
      //filter(([previousValue, currentValue]) => (previousValue === 'loading' || previousValue === 'none') && currentValue === 'ok'),
    ).subscribe(() => {
      if(!this.baseMapIsLoading)
      {
        console.log("La valeur est passée de 'loading' à 'ok'", 'Timestamp:', new Date().toISOString());
        if(this.authService.CurrentHypercubeSubject.value !== null)
        {
          this.toastr.info(this.translate.instant('HYPERCUBE_LOADING'), this.translate.instant('PLEASE_WAIT'));
          this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);
        }
      }
    });




    this.isToolbarVisible$.subscribe(value => {
      console.log('this.isToolbarVisible$.subscribe');
      console.log(value);
      if(value)
      {
        console.log('this.isToolbarVisible$.subscribe has value');
        this.toolbarElement = document.getElementById('map-toolbar');
        console.log(this.toolbarElement);
        if (this.toolbarElement) {
          console.log('position');
          console.log(this.toolbarPosition);
            this.toolbarElement.style.left = `${this.toolbarPosition.left}px`;
            this.toolbarElement.style.top = `${this.toolbarPosition.top}px`;
            this.toolbarElement.style.transform = 'translateX(0)'; // Enlevez le centrage initial
            console.log(this.toolbarElement.style.left);
        }
      }
    });

    /*this.authService.hypercubeStatus$.subscribe(value => {
      if(value === 'ok')
      {
        console.log('clearing notifications');
        this.clearNotifications();
        if(!this.mapNode)
        {
          this.transitionTo(this.authService.CurrentHypercubeSubject.value.latitude, this.authService.CurrentHypercubeSubject.value.longitude, 17);
          this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);
        }
        

      }
    });*/

    /*this.authService.CurrentHypercube$.subscribe(value => {
      console.log('Carto : CurrentHypercube changed');
      console.log(this.authService.hypercubeStatusSubject.value);
      if(this.authService.hypercubeStatusSubject.value === 'ok')
      {
        console.log('Carto : hypercubeStatusSubject.value ok');
        if(!this.mapNode)
        {
          console.log('Carto : mapNode null');
          this.transitionTo(this.authService.CurrentHypercubeSubject.value.latitude, this.authService.CurrentHypercubeSubject.value.longitude, 17);
          this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);
        }
        

      }
    });*/

    this.authService.CurrentHypercube$.subscribe(value => {
      console.log('Carto : CurrentHypercube changed');
      this.currentHypercube = value;
      console.log('this.currentHypercube =');
      console.log(this.currentHypercube);
      console.log(this.authService.hypercubeStatusSubject.value);
      if(this.authService.hypercubeStatusSubject.value === 'ok')
      {
        console.log('Carto : hypercubeStatusSubject.value ok');
        if(!this.mapNode)
        {
          console.log('Carto : mapNode null 2');
          //this.transitionTo(this.authService.CurrentHypercubeSubject.value.latitude, this.authService.CurrentHypercubeSubject.value.longitude, 16);
          //this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);

          //this.loadRasterLayer(this.authService.CurrentHypercubeSubject.value);

          //this.transitionTo(this.authService.CurrentHypercubeSubject.value.latitude, this.authService.CurrentHypercubeSubject.value.longitude, 16);
          
        }
        

      }
    });
    //this.codeEditor.toggleCodeEditor();
    this.map.invalidateSize();
  }

  ngOnDestroy() {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
    if (this.hypercubeStatusSubscription) {
      this.hypercubeStatusSubscription.unsubscribe();
    }
    if (this.segmentsVisibilitySubscription) {
      this.segmentsVisibilitySubscription.unsubscribe();
    }
    if (this.segmentsLayer) {
      this.map.removeLayer(this.segmentsLayer);
    }
    this.map.remove();
  }
  async ngOnInit() {

    console.log('constructor cartography');
    let userDataPath = '';
    if (window && (window as any).electronAPI) {
      console.log('if');
      userDataPath =  await (window as any).electronAPI.getUserDataPath();
      this.tilesUrl = this.tilesUrl.replace('%LOCALAPPDATA%', userDataPath.replace(/\\/g, '/'));
    }
    console.log('tilesUrl');
    console.log(this.tilesUrl);
    this.logoutSubscription = this.authService.logoutEvent.subscribe(data => {
      this.clearAllNotifications();
    });

    this.errorSubscription = this.authService.errorEvent.subscribe(data => {
      this.clearAllNotifications();
      this.toastr.error(data);
    });

    this.advancedFeaturesService.segmentsLoaded$.subscribe(segments => {
      if (segments) {
        this.displaySegmentsOnMap(segments);
      }
    });

    // Modifier la souscription à segmentsVisibility$
    this.segmentsVisibilitySubscription = this.advancedFeaturesService.segmentsVisibility$.subscribe(visible => {
      if (visible) {
        this.segmentLayers.forEach(layer => {
          if (!this.map.hasLayer(layer)) {
            layer.addTo(this.map);
          }
        });
      } else {
        this.segmentLayers.forEach(layer => {
          if (this.map.hasLayer(layer)) {
            this.map.removeLayer(layer);
          }
        });
      }
    });


    this.ROIPointSubscription = this.mapService.getROIPoint().subscribe({
      next: (point) => {
        //if (point) {
          this.onROIPointChange(point);
        //}
      },
      error: (err) => console.error(err),
    });

    //this.map = L.map("map");
    /*this.map = L.map('map', {
        zoomControl: false
    });
    L.control.zoom({
        position: 'topright' // ou 'bottomright' selon vos préférences
    }).addTo(this.map);*/

    /*L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);*/

    this.map = L.map('map', {
        zoomControl: false
    });
    this.map.createPane('backgroundMap');
    let paneBackgroundMap = this.map.getPane('backgroundMap');
    if (paneBackgroundMap) {
      paneBackgroundMap.style.zIndex = '301';
    }

    this.map.createPane('hypercubeMarkerPane');
    let pane = this.map.getPane('hypercubeMarkerPane');
    if (pane) {
      pane.style.zIndex = '300';
    }


    this.map.createPane('appLayerPane');
    let paneAppLayer = this.map.getPane('appLayerPane');
    if (paneAppLayer) {
      paneAppLayer.style.zIndex = '600';
    }
    

    L.control.zoom({
        position: 'bottomright' // ou 'bottomright' selon vos préférences
    }).addTo(this.map);


    /*L.tileLayer("https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png", {
          attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>'
    }).addTo(this.map);*/

    /*L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution:
          '&copy; OSM Mapnik <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    }).addTo(this.map);*/

    //this.baseMapLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      //attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    //}).addTo(this.map);



//TEST

    this.baseMapLayer = L.tileLayer(`${this.tilesUrl}`, {
      maxZoom: 19,
      //attribution: '&copy; Local Tiles'
    }).addTo(this.map);

    /*this.baseMapLayer = L.tileLayer('file:///C:/Temp/TILES/{z}/{x}/{y}.png', {
        maxZoom: 19,
        attribution: '&copy; Local Tiles'
    }).addTo(this.map);*/

    /*let topoLayer = L.tileLayer('file:///C:/Temp/Tiles inde/{z}/{x}/{y}.png', {
      maxZoom: 18,
      attribution: '© OpenStreetMap Contributors'
    });
    
    let localTiles = L.tileLayer('file:///C:/Temp/TILES/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '&copy; Local Tiles'
    });
    
    this.map.addLayer(topoLayer);
    this.map.addLayer(localTiles);*/

//TEST

/*new (L.control as any).basemapsSwitcher([
    {
      layer: L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OSM Mapnik <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map), //DEFAULT MAP
      icon: '../images/smooth.png',
      name: 'Smooth'
    },
    {
      layer: L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',{
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="https://carto.com/attributions">CARTO</a>'
      }),
      icon: '../images/dark.png',
      name: 'Dark'
    },
  ], { position: 'bottomright' }).addTo(this.map);*/


    /*document.addEventListener('visibilitychange', function() {
      if (document.visibilityState === 'visible') {
          console.log('visibilitychange');
      }
  });*/


    //this.map.setView([43.635174, 1.398204], 17);
    //this.map.setView([43.635174, 1.398204], 10);
    this.map.setView([43.635174, 1.398204], 3);

    this.map.pm.addControls({  
      position: 'topleft',  
      drawCircle: false,
      rotateMode: false,
      dragMode: false,
      drawCircleMarker:false,
      drawPolyline:false,
      drawRectangle:false,
      editMode:false,
      cutPolygon:false,
      oneBlock: true,  
    });



    /*this.map.pm.Toolbar.createCustomControl({
      name: 'AppStoreButton',
      className: 'action-appstore',
      toggle: true,
      title: 'Applications', 
      onClick: () => {
        this.openAppStore();
      },
    });*/
    const appStoreAction = {
      name: 'AppStoreButton',
      className: 'action-appstore',
      toggle: true,
      onClick: () => {
        this.openAppStore();
      },
    };
    this.map.pm.Toolbar.createCustomControl(appStoreAction);



    const hypercubeSelectorAction = {
      name: 'HypercubeSelectorButton',
      className: 'action-hypercubeSelector',
      toggle: true,
      title: 'Hypercubes',
      onClick: () => {
        this.hypercubeSelector.toggleView();
      }
    };
    this.map.pm.Toolbar.createCustomControl(hypercubeSelectorAction);

    const libraryAction = {
      name: 'LibraryButton',
      className: 'action-library',
      toggle: true,
      title: 'Library', 
      onClick: () => {
        this.openLibrary();
      },
    };
    this.map.pm.Toolbar.createCustomControl(libraryAction);

    const terminalAction = {
      name: 'TerminalButton',
      className: 'action-terminal',
      toggle: false,
      title: 'Terminal', 
      onClick: () => {
        this.openTerminal();
      },
    };
    this.map.pm.Toolbar.createCustomControl(terminalAction);

    const rendezvousAction = {
      name: 'RendezvousButton',
      className: 'action-rendezvous',
      toggle: true,
      title: 'Rendez Vous', 
      onClick: () => {
        this.openRendezvousList();
      },
    };
    this.map.pm.Toolbar.createCustomControl(rendezvousAction);

    const catalogAction = {
      name: 'CatalogButton',
      className: 'action-catalog',
      toggle: true,
      title: 'Catalog', 
      onClick: () => {
        this.openCatalog();
      },
    };
    this.map.pm.Toolbar.createCustomControl(catalogAction);


    var infoMarker = this.map.pm.Toolbar.copyDrawControl('drawMarker',{name: "getCoordinates", className: 'action-segment', title: 'Segment' });

    //var infoMarker = this.map.pm.Toolbar.copyDrawControl('drawMarker',{name: "getCoordinatesRendezVous", className: 'action-getrendezvous', title: 'Rendez Vous' });
    
    /*this.initPolylineMeasure();
    this.map.pm.Toolbar.createCustomControl({
      name: 'MeasureButton',
      className: 'action-measure',
      toggle: true, 
      title: 'Measure', 
      onClick: () => {
        this.polyMeasure._toggleMeasure();
      },
    });*/


    //Position of toolbar
/*    let toolbarContainer = L.DomUtil.create('div', 'leaflet-pm-toolbar-container');
    this.map.whenReady(() => {
      let toolbar = document.querySelector(".leaflet-pm-toolbar");
      if (toolbar) {
        toolbarContainer.appendChild(toolbar);
        this.map.getContainer().appendChild(toolbarContainer);

      }
    });*/
    //Position of toolbar

    const toolbarContainer2 = document.querySelector('.leaflet-pm-toolbar');
    console.log('toolbarContainer2');
    console.log(toolbarContainer2);
    if (toolbarContainer2) {

        const terminalButton = document.querySelector('.action-terminal');
        if(terminalButton && terminalButton.parentNode && terminalButton.parentNode.parentNode)
        {
          toolbarContainer2.insertBefore(terminalButton.parentNode.parentNode, toolbarContainer2.firstChild);
        }

        const rendezvousButton = document.querySelector('.action-rendezvous');
        if(rendezvousButton && rendezvousButton.parentNode && rendezvousButton.parentNode.parentNode)
        {
          toolbarContainer2.insertBefore(rendezvousButton.parentNode.parentNode, toolbarContainer2.firstChild);
        }

        const catalogButton = document.querySelector('.action-catalog');
        if(catalogButton && catalogButton.parentNode && catalogButton.parentNode.parentNode)
        {
          toolbarContainer2.insertBefore(catalogButton.parentNode.parentNode, toolbarContainer2.firstChild);
        }

        const customButton2 = document.querySelector('.action-hypercubeSelector');
        if(customButton2 && customButton2.parentNode && customButton2.parentNode.parentNode)
        {
          toolbarContainer2.insertBefore(customButton2.parentNode.parentNode, toolbarContainer2.firstChild);
        }

        const libraryButton = document.querySelector('.action-library');
        if(libraryButton && libraryButton.parentNode && libraryButton.parentNode.parentNode)
        {
          toolbarContainer2.insertBefore(libraryButton.parentNode.parentNode, toolbarContainer2.firstChild);
        }

        const appButton = document.querySelector('.action-appstore');
        if(appButton && appButton.parentNode && appButton.parentNode.parentNode)
        {
          toolbarContainer2.insertBefore(appButton.parentNode.parentNode, toolbarContainer2.firstChild);
        }


    }

    this.map.on('contextmenu', (event) => {
      this.lastRightClickLatLng = event.latlng;
      const clickCoords = event.latlng; // Obtenir les coordonnées du clic
      console.log('contextmenu');
      const point = this.map.latLngToContainerPoint(clickCoords);
      const positionStrategy = this.overlay.position().flexibleConnectedTo({ x: point.x, y: point.y })
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top'
      }]);
      this.overlayRef = this.overlay.create({ positionStrategy });
      const portal = new TemplatePortal(this.contextMenu, this.viewContainerRef);
      this.overlayRef.attach(portal);

      this.overlayRef.outsidePointerEvents().subscribe(() => {
        this.closeContextMenu();
      });
    });

    this.map.on('zoomend', () => {
      this.updateTextSize(this.map, this.textMarkers);

      this.updateHypercubePoiDisplay();
    });

    this.map.on('moveend', () => {
      const center = this.map.getCenter();
      this.mapService.setCenter(center.lat, center.lng);
    });


    this.map.on('click', () => {
      
      if(this.selectedItem && this.selectedItem.dragging)
      {
        console.log('on click disable selected item');
        this.selectedItem.dragging.disable();
        (this.selectedItem as any).isDraggingEnabled = false;
      }
      this.hideToolbar();

    });
    //After polygon drawn
    this.map.on('pm:create', (event: any) => {

      if (event.shape === 'Polygon') {
        //this.openDialog(event.layer);
        console.log('Polygon drawn');
        console.log(event);
        //this.map.removeLayer(event.layer);
        event.layer.setStyle({ color: 'rgb(90, 90, 90)', fill:'rgb(100, 100, 100)'  });
        console.log(JSON.stringify(event.layer.toGeoJSON()));

        var l = event.layer.toGeoJSON();
        l.properties.name = "Polygon";

        /*let newNode = this.layerLibrary.createNode(l.properties.name, l, l ,"", "Segmentations");
        this.layerLibrary.openEditDialog(newNode);
        this.saveTreeNodes();*/

        /*event.marker.on('click', () => {
          console.log('polygon clicked');
          this.selectedItem = event.marker;
          this.isToolbarVisibleSubject.next(true);
        });*/

        /*event.marker.on('click', () => {
          console.log('polygon clicked');
          this.selectedItem = event.marker;
        });*/

        
        
        
        this.layerLibrary.openCreateNodeNameDialog('Polygon').then(result => {
          
          if(result)
          {
            console.log('nodeName');
            console.log(result);
    
            var notification = this.toastr.info('Spectrum loading : ' + result, 'Loading');
            console.log('notificationId : ' + notification.toastId);
    
    
    
            const options = {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')  
              },
              credentials: 'include' as RequestCredentials 
            };
    
            fetch(`${this.apiUrl}/${this.endPointSegmentationPolygon}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token')
              },
              credentials: 'include' as RequestCredentials,
              body: JSON.stringify(this.simplifyCoordinates(event.layer.toGeoJSON())),
              })
              .then(response => response.json())
              .then((data) => {
                  console.log('python response endPointSegmentationPolygon ' + JSON.stringify(data));
                  l.properties = {}
                  l.properties.reflectance_profile = data
                  //let newNode = this.layerLibrary.createNode(l.properties.name, l, l ,"", "Segmentations");
    
    
    
                  let segment = L.geoJson(l, {
                    onEachFeature:(feature, layer) => {
                      console.log("onEachFeature");
                      (layer as any).properties = feature.properties;
                      (layer as any).properties.name = "Segment";
                      event.layer.setStyle({ color: 'rgb(51, 136, 255)', fill:'rgb(51, 136, 255)'  });
                      let newNode = this.layerLibrary.createNode(result, layer, feature ,"", "Segmentations");
                      //this.layerLibrary.openEditDialog(newNode);
                      this.saveTreeNodes();
                      console.log(feature.properties);
                      this.clearNotificationById(notification.toastId, 'Success', 'Spectrum loaded');
                    }
                  }).addTo(this.map);
                  this.map.removeLayer(event.layer);
                segment.on('click', (e) => {
                  console.log('segment on lick')
                  if (this.selectedLayers.has(e.layer)) {
    
                    //e.layer.setStyle({ color: 'blue' }); // Ou votre style de layer par défaut
                    e.layer.setStyle({ color: 'rgb(51, 136, 255)', fill:'rgb(51, 136, 255)'  });
                    this.selectedLayers.delete(e.layer);
                    this.layerLibrary.updateChart(this.selectedLayers);
                  } 
                  // Si le layer n'est pas sélectionné
                  else {
                    // Sélectionner le layer
                    this.selectedLayers.add(e.layer);
                    e.layer.setStyle({ color: 'green' }); // Ou votre style de layer sélectionné
                    console.log("Layer name : ");
                    console.log(e);
                    console.log((e.layer as any).properties.name);
                    this.layerLibrary.updateChart(this.selectedLayers);
                  }
                });
    
    
                  //this.layerLibrary.openEditDialog(newNode);
                  //this.saveTreeNodes();
              })
              .catch((error) => {
                  console.error('Error:', error);
              });
          }
          else
          {
            this.map.removeLayer(event.layer);
          }

        }).catch(erreur => {

          console.error(erreur);
        });

        



      }
      else if (event.shape === 'Marker') {
        this.map.pm.disableDraw('Marker');
        event.marker.isDraggingEnabled = false;
        console.log('marker drawn');
        console.log(event);
        /*event.marker.on('click', () => {
          console.log('marker clicked');
          this.selectedItem = event.marker;
          this.isToolbarVisibleSubject.next(true);
        });*/

        var l = event.layer.toGeoJSON();
        l.properties.name = "Marker";


        let parameters = {
          lat: event.layer.getLatLng().lat,
          long: event.layer.getLatLng().lng
        };

        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')  
          },
          credentials: 'include' as RequestCredentials 
        };

        fetch(`${this.apiUrl}/${this.endPointSegmentationMarker}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
          credentials: 'include' as RequestCredentials,
          body: JSON.stringify(parameters),
          })
          .then(response => response.json())
          .then((data) => {
              console.log('python response endPointSegmentationMarker ' + JSON.stringify(data));


              l.properties = {}
              l.properties.reflectance_profile = data

              let segment = L.geoJson(l, {
                onEachFeature:(feature, layer) => {
                  console.log("onEachFeature");
                  (layer as any).properties = feature.properties;
                  (layer as any).properties.name = "Marker";
                  //event.layer.setStyle({ color: 'rgb(51, 136, 255)', fill:'rgb(51, 136, 255)'  });
                  let newNode = this.layerLibrary.createNode((layer as any).properties.name, layer, feature ,"", "Segmentations");
                  this.layerLibrary.openEditDialog(newNode);
                  console.log(feature.properties);
                }
              }).addTo(this.map);

            segment.on('click', (e) => {
              console.log('segment on lick')
              if (this.selectedLayers.has(e.layer)) {

                //e.layer.setStyle({ color: 'blue' }); // Ou votre style de layer par défaut
                //e.layer.setStyle({ color: 'rgb(51, 136, 255)', fill:'rgb(51, 136, 255)'  });
                this.selectedLayers.delete(e.layer);
                this.layerLibrary.updateChart(this.selectedLayers);
              } 
              // Si le layer n'est pas sélectionné
              else {
                // Sélectionner le layer
                this.selectedLayers.add(e.layer);
                //e.layer.setStyle({ color: 'green' }); // Ou votre style de layer sélectionné
                console.log("Layer name : ");
                console.log(e);
                console.log((e.layer as any).properties.name);
                this.layerLibrary.updateChart(this.selectedLayers);
              }
            });


              //this.layerLibrary.openEditDialog(newNode);
              //this.saveTreeNodes();








          })
          .catch((error) => {
              console.error('Error:', error);
          });




      }
      else if(event.shape == 'getCoordinates')
      {
          console.log(event.layer.getLatLng());
          this.map.removeLayer(event.layer);
          let parameters = {
              lat: event.layer.getLatLng().lat,
              long: event.layer.getLatLng().lng
          };
          console.log('log1');
          var segmentationNotification = this.toastr.info('Segmentation', this.translate.instant('PLEASE_WAIT'));
          console.log('log2');
  
          //fetch('http://localhost:3000/segmentation', {
            const options = {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')  // Assurez-vous que le préfixe "Bearer " est correct.
              },
              credentials: 'include' as RequestCredentials  // Important si vous utilisez des sessions ou des cookies.
            };
            console.log('log3');
            fetch(`${this.apiUrl}/${this.endPointSegmentation}`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('token')
              },
              credentials: 'include' as RequestCredentials,
              body: JSON.stringify(parameters),
              })
              //.then(response => response.json())
              .then(response => {
                console.log('log4');
                console.log(response);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
              .then((data) => {
                  console.log('python response ' + JSON.stringify(data));

                  if(data.geometry == null)
                  {
                    this.clearNotificationById(segmentationNotification.toastId, '', 'No segment found');
                  }

                  let segment = L.geoJson(data, {

                    onEachFeature:(feature, layer) => {
                      console.log("onEachFeature");
                      
                      (layer as any).properties = feature.properties;
                      (layer as any).properties.name = "Segment";
                      let newNode = this.layerLibrary.createNode((layer as any).properties.name, layer, feature ,"", "Segmentations");
                      this.layerLibrary.openEditDialog(newNode);
                      console.log(feature.properties);
                      this.clearNotificationById(segmentationNotification.toastId, '', 'Success');
                    }
                  }).addTo(this.map);
                  
                segment.on('click', (e) => {

                  if (this.selectedLayers.has(e.layer)) {
                    e.layer.setStyle({ color: 'rgb(51, 136, 255)', fill:'rgb(51, 136, 255)'  });
                    this.selectedLayers.delete(e.layer);
                    this.layerLibrary.updateChart(this.selectedLayers);
                  } 
                  else {
                    // Sélectionner le layer
                    this.selectedLayers.add(e.layer);
                    e.layer.setStyle({ color: 'green' }); 
                    console.log("Layer name : ");
                    console.log(e);
                    console.log((e.layer as any).properties.name);
                    this.layerLibrary.updateChart(this.selectedLayers);
                  }
                });
                //this.saveTreeNodes();

                this.applicationLayers.push(segment);
  
              })
              .catch((error) => {
                  console.error('Error:', error);
              });
      }
      else if(event.shape == 'getCoordinatesRendezVous')
      {



      }

    });

    this.map.on('pm:remove', (event: any) => {
      const layer = event.layer;
      console.log('pm:remove');
      console.log(layer);
      this.layerLibrary.deleteNodeByLayer(layer);
      this.layerLibrary.updateChart(this.selectedLayers);
    });
    
    //this.loadRasterLayer();
    this.updateHypercubePoiDisplay();
  }

  simplifyCoordinates(geoJson) {
    if (geoJson.geometry && geoJson.geometry.coordinates) {
      let coordinates = geoJson.geometry.coordinates;
      if (coordinates.length === 1 && Array.isArray(coordinates[0])) {
        geoJson.geometry.coordinates = coordinates[0];
      }
    }
    return geoJson;
  }

  clearAllNotifications()
  {
    this.toastr.clear();
  }


  clearNotifications(title, message)
  {
    this.toastr.clear();
    if(this.toastr.currentlyActive > 0)
    {
      //this.toastr.success('Succès.', 'Hypercube chargé avec succès', {timeOut: 5000, extendedTimeOut:2000})
      this.toastr.success(title, message, {timeOut: 5000, extendedTimeOut:2000})
    }
    
  }

  clearNotificationById(id, title, message)
  {
    this.toastr.clear(id);
    if(this.toastr.currentlyActive > 0)
    {
      //this.toastr.success('Succès.', 'Hypercube chargé avec succès', {timeOut: 5000, extendedTimeOut:2000})
      this.toastr.success(title, message, {timeOut: 5000, extendedTimeOut:2000})
    }
    
  }

  darkenBaseMap(opacity: number) {
    var container = this.baseMapLayer.getContainer() as HTMLElement;
    container.style.filter = 'brightness('+ opacity +'%)';
  }

  saveTreeNodes(){
    console.log('saveTreeNodes');
    const jsonData = this.layerLibrary.exportTreeDataToJSON();

    //fetch(`http://localhost:3000/save`, {
    fetch(`${this.apiUrl}/save`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      credentials: 'include' as RequestCredentials,
      body: jsonData
      })
  }


  importTreeNodes(nodes : any[] | null)
  {
    console.log('importTreeNodes');
    console.log(nodes);

    if(nodes)
    {
        for (let node of nodes) {
          console.log(node.name);
          if(node.name === 'Segmentations' && node.children)
          {
            for (let importedsegment of node.children) {
              console.log(importedsegment.name);
              console.log(importedsegment);
              console.log(importedsegment.GeoJSON);

              /*let segment = L.geoJson(importedsegment.GeoJSON).addTo(this.map);
              console.log('after creating segment');
              console.log(segment);
              (segment as any).properties = importedsegment.GeoJSON.properties;
              (segment as any).properties.name = importedsegment.name;*/
              let segment = L.geoJson(importedsegment.GeoJSON, {
                onEachFeature:(feature, layer) => {
                  console.log("onEachFeature");
                  
                  (layer as any).properties = feature.properties;
                  (layer as any).properties.name = importedsegment.name;
                  let newNode = this.layerLibrary.createNode((layer as any).properties.name, layer, feature ,"", "Segmentations");
                  //this.layerLibrary.openEditDialog(newNode);
                  console.log(feature.properties);

                  
                  // Calcul du centre du polygone
                  //let center = (layer as any).getBounds().getCenter();

                  // Création d'un marqueur de texte au centre du polygone
                  /*let textMarker = L.marker(center, {
                    icon: L.divIcon({
                      className: 'polygon-label', 
                      html: (layer as any).properties.name 
                    })}).addTo(this.map);
                    this.textMarkers.push(textMarker);
                    this.updateTextSize(this.map, this.textMarkers);*/

                }
              }).addTo(this.map);

              segment.on('click', (e) => {
                /*e.target.setStyle({
                  color: '#36A300', 
                  fillOpacity: 0.5,  
                });

                console.log((e.layer as any).properties);
                this.layerLibrary.updateChart((e.layer as any).properties.reflectance_profile);*/

                console.log('Segment click : ' );
                console.log(segment);
                console.log('Segment click event : ');
                console.log(e);

                if (this.selectedLayers.has(e.layer)) {
                  // Désélectionner le layer
                  //e.layer.setStyle({ color: 'blue' });
                  e.layer.setStyle({ color: 'rgb(51, 136, 255)', fill:'rgb(51, 136, 255)'  });
                  this.selectedLayers.delete(e.layer);
                  this.layerLibrary.updateChart(this.selectedLayers);
                } 
                // Si le layer n'est pas sélectionné
                else {
                  // Sélectionner le layer
                  this.selectedLayers.add(e.layer);
                  e.layer.setStyle({ color: 'green' }); // Ou votre style de layer sélectionné
                  //this.layerLibrary.updateChart((e.layer as any).properties.reflectance_profile);
                  console.log("Layer name : ");
                  console.log(e);
                  console.log(e.layer);
                  console.log((e.layer as any).feature.properties);
                  console.log((e.layer as any).feature.properties.name);
                  this.layerLibrary.updateChart(this.selectedLayers);

                  /*if(!this.layerLibrary.isPanelOpen)
                    this.layerLibrary.togglePanel();*/
                }
              });


            }
          }
          
        }
    }

  }

  updateTextSize(map, markers) {
    let zoomLevel = map.getZoom();
    let newSize = zoomLevel / 1.5; // Exemple de formule, à ajuster selon vos besoins
  
    markers.forEach(marker => {
      let icon = marker.getIcon();
      icon.options.html = '<span style="font-size:' + newSize + 'px;">' + marker.getIcon().options.html + '</span>';
      marker.setIcon(icon);
    });
  }


  updateHypercubePoiDisplay()
  {
    console.log('updateHypercubePoiDisplay');
    console.log(this.displayHypercubeCatalogGroundMark);
    /*if(this.map && this.displayHypercubeCatalogGroundMark)
    {
      console.log(this.hypercubeMarkers);
      var currentZoom = this.map.getZoom();

      this.hypercubeMarkers.forEach(poiMarker => {
        if (currentZoom < 10) {
          if (!this.map.hasLayer(poiMarker)) {
              poiMarker.addTo(this.map);
          }
        } else {
            if (this.map.hasLayer(poiMarker)) {
                this.map.removeLayer(poiMarker);
            }
        }
      });
      if (this.map && this.map.hasLayer(this.backgroundImageLayer)) {
        this.map.removeLayer(this.backgroundImageLayer);
        this.map.addLayer(this.backgroundImageLayer);
      }
    }*/


    /*var currentZoom = this.map.getZoom();
    if(!this.currentHypercube)
    {
      this.hypercubeMarkers.forEach(poiMarker => {
          if (currentZoom < 10) {
            if (!this.map.hasLayer(poiMarker)) {
                poiMarker.addTo(this.map);
            }
          } else {
              if (this.map.hasLayer(poiMarker)) {
                  this.map.removeLayer(poiMarker);
              }
          }
      
      });
    }*/
    
    if(!this.currentHypercube && this.map)
    {
      var currentZoom = this.map.getZoom();
      this.hypercubeMarkers.forEach(poiMarker => {
          if (currentZoom < 10) {
            if (!this.map.hasLayer(poiMarker)) {
                poiMarker.addTo(this.map);
            }
          } else {
              if (this.map.hasLayer(poiMarker)) {
                  this.map.removeLayer(poiMarker);
              }
          }
      
      });
    }
    else
    {
      this.HypercubeMap.forEach((value: L.Layer[], key: string) => {
        if(this.map.hasLayer(value[0])) 
        {
          this.map.removeLayer(value[0]);
        }

        if(this.map.hasLayer(value[1])) 
        {
          this.map.removeLayer(value[1]);
        }
          
      });
    }

  }



 highlightFeature(e) {
    var layer = e.target;

    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
        layer.bringToFront();
    }
    e.target.setStyle({
      color: '#ff0000',  // La couleur de la ligne.
      fillColor: '#ffff00',  // La couleur de remplissage.
      fillOpacity: 0.5,  // L'opacité du remplissage.
    });
    console.log(layer.feature.properties);
    //info.update(layer.feature.properties);
    //controlDiv.style.display = 'block';

}

resetHighlight(e) {
    //controlDiv.style.display = 'none';
    /*e.target.setStyle({
      color: '#ff0000',  // La couleur de la ligne.
      fillColor: '#ffff00',  // La couleur de remplissage.
      fillOpacity: 0.5,  // L'opacité du remplissage.
    });*/
}


  removeLayer(layer: L.Layer){
    this.map.removeLayer(layer);
  }

  initPolylineMeasure(){
      var options = {
        position: 'topleft',            // Position to show the control. Values: 'topright', 'topleft', 'bottomright', 'bottomleft'
        unit: 'kilometres',             // Default unit the distances are displayed in. Values: 'kilometres', 'landmiles', 'nauticalmiles'
        useSubunits: true,              // Use subunits (metres/feet) in tooltips if distances are less than 1 kilometre/landmile
        clearMeasurementsOnStop: true,  // Clear all measurements when Measure Control is switched off
        showBearings: false,            // Whether bearings are displayed within the tooltips
        bearingTextIn: 'In',            // language dependend label for inbound bearings
        bearingTextOut: 'Out',          // language dependend label for outbound bearings
        tooltipTextFinish: 'Click <b>finish line</b><br>',
        tooltipTextDelete: 'Press SHIFT-key and click to <b>delete point</b>',
        tooltipTextMove: 'Click and drag to <b>move point</b><br>',
        tooltipTextResume: '<br>Press CTRL-key and click to <b>resume line</b>',
        tooltipTextAdd: 'Press CTRL-key and click to <b>add point</b>',
                                        // language dependend labels for point's tooltips
        measureControlTitleOn: 'Turn on PolylineMeasure',   // Title for the Measure Control going to be switched on
        measureControlTitleOff: 'Turn off PolylineMeasure', // Title for the Measure Control going to be switched off
        measureControlLabel: '&#8614;', // Label of the Measure Control (Unicode symbols are possible)
        measureControlClasses: [],      // Classes to apply to the Measure Control
        showClearControl: false,        // Show a control to clear all the measurements
        clearControlTitle: 'Clear Measurements', // Title text to show on the Clear Control
        clearControlLabel: '&times',    // Label of the Clear Control (Unicode symbols are possible)
        clearControlClasses: [],        // Classes to apply to Clear Control
        showUnitControl: false,         // Show a control to change the units of measurements
        unitControlUnits: ["kilometres", "landmiles", "nauticalmiles"],
                                        // measurement units being cycled through by using the Unit Control
        unitControlTitle: {             // Title texts to show on the Unit Control
            text: 'Change Units',
            kilometres: 'kilometres',
            landmiles: 'land miles',
            nauticalmiles: 'nautical miles'
        },
        unitControlLabel: {             // Unit symbols to show in the Unit Control and measurement labels
            metres: 'm',
            kilometres: 'km',
            feet: 'ft',
            landmiles: 'mi',
            nauticalmiles: 'nm'
        },
        unitControlClasses: [],         // Classes to apply to the Unit Control
        tempLine: {                     // Styling settings for the temporary dashed line
            color: '#00f',              // Dashed line color
            weight: 2                   // Dashed line weight
        },          
        fixedLine: {                    // Styling for the solid line
            color: '#006',              // Solid line color
            weight: 2                   // Solid line weight
        },
        arrow: {                        // Styling of the midway arrow 
            color: '#000',              // Color of the arrow
        },
        startCircle: {                  // Style settings for circle marker indicating the starting point of the polyline
            color: '#000',              // Color of the border of the circle
            weight: 1,                  // Weight of the circle
            fillColor: '#0f0',          // Fill color of the circle
            fillOpacity: 1,             // Fill opacity of the circle
            radius: 3                   // Radius of the circle
        },
        intermedCircle: {               // Style settings for all circle markers between startCircle and endCircle
            color: '#000',              // Color of the border of the circle
            weight: 1,                  // Weight of the circle
            fillColor: '#ff0',          // Fill color of the circle
            fillOpacity: 1,             // Fill opacity of the circle
            radius: 3                   // Radius of the circle
        },
        currentCircle: {                // Style settings for circle marker indicating the latest point of the polyline during drawing a line
            color: '#000',              // Color of the border of the circle
            weight: 1,                  // Weight of the circle
            fillColor: '#f0f',          // Fill color of the circle
            fillOpacity: 1,             // Fill opacity of the circle
            radius: 6                   // Radius of the circle
        },
        endCircle: {                    // Style settings for circle marker indicating the last point of the polyline
            color: '#000',              // Color of the border of the circle
            weight: 1,                  // Weight of the circle
            fillColor: '#f00',          // Fill color of the circle
            fillOpacity: 1,             // Fill opacity of the circle
            radius: 3                   // Radius of the circle
        },
    };
    this.polyMeasure = new L.Control.PolylineMeasure(options);
    this.polyMeasure.addTo(this.map);



  }

  addRoiForRendezVous(){
    if (this.lastRightClickLatLng) {

      if(this.rendezvousMarker)
      {
        this.map.removeLayer(this.rendezvousMarker);
      }

      //this.rendezVousList.createGeoPointWithCoordinates(this.lastRightClickLatLng);
      let location: { latitude: number, longitude: number } = {
        latitude: this.lastRightClickLatLng.lat,
        longitude: this.lastRightClickLatLng.lng
      };
      this.mapService.createROIPointWithCoordinates(location);
      this.closeContextMenu();
  
      const toolIcon = document.querySelector('.action-rendezvous'); 
      /*console.log('querySelector');
      console.log(toolIcon);
      if (toolIcon) {
        toolIcon.classList.add('active'); 
      }*/


      setTimeout(() => {
        // Simuler un clic sur le bouton custom
        if(toolIcon && !toolIcon?.parentElement?.parentElement?.classList.contains('active')){
          (toolIcon as HTMLElement).click();
        }
        
      }, 100);
    }

  }

  removeRoiForRendezVous(){
    if(this.rendezvousMarker)
    {
      this.map.removeLayer(this.rendezvousMarker);
      this.mapService.removeROIPoint();
    }
    this.closeContextMenu();
  }

  

  closeContextMenu(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null; 
    }
  }

  private displaySegmentsOnMap(segments: any) {
    // Supprimer les segments existants s'il y en a
    this.segmentLayers.forEach(layer => {
      if (this.map.hasLayer(layer)) {
        this.map.removeLayer(layer);
      }
    });
    this.segmentLayers = [];

    console.log('Nombre de segments à afficher:', segments.length);
    console.log('Premier segment:', JSON.stringify(segments[0], null, 2));

    // Vérifier que les segments sont bien un tableau
    if (!Array.isArray(segments)) {
      console.error('Les segments ne sont pas un tableau:', segments);
      return;
    }

    // Itérer sur chaque segment
    segments.forEach((segment, index) => {
      console.log(`Traitement du segment ${index + 1}/${segments.length}:`, segment);
      
      // Vérifier que le segment a une géométrie valide
      if (!segment.geometry || !segment.geometry.coordinates) {
        console.error(`Segment ${index + 1} n'a pas de géométrie valide:`, segment);
        return;
      }

      // Créer une couche GeoJSON pour le segment
      const layer = L.geoJSON(segment, {
        style: {
          color: '#3388ff',
          weight: 2,
          fillOpacity: 0.2,
          fillColor: '#3388ff',
          pane: 'appLayerPane'
        },
        onEachFeature: (feature, layer) => {
          (layer as any).properties = feature.properties;
          (layer as any).properties.name = "Segment";
          
          // Ajouter le gestionnaire de clic
          layer.on('click', (e) => {
            console.log('segment on click');
            if (this.selectedLayers.has(e.layer)) {
              e.layer.setStyle({ color: '#3388ff', fillColor: '#3388ff' });
              this.selectedLayers.delete(e.layer);
              this.layerLibrary.updateChart(this.selectedLayers);
            } else {
              e.layer.setStyle({ color: 'green', fillColor: 'green' });
              this.selectedLayers.add(e.layer);
              console.log("Layer name : ");
              console.log(e);
              console.log((e.layer as any).properties.name);
              this.layerLibrary.updateChart(this.selectedLayers);
            }
          });

          // Ajouter un effet de survol
          layer.on('mouseover', (e) => {
            e.layer.setStyle({
              weight: 3,
              fillOpacity: 0.3
            });
          });

          layer.on('mouseout', (e) => {
            e.layer.setStyle({
              weight: 2,
              fillOpacity: 0.2
            });
          });
        }
      });

      // Ajouter le segment à la carte et le stocker dans le tableau
      layer.addTo(this.map);
      this.segmentLayers.push(layer);
    });

    // Ajuster la vue pour montrer tous les segments
    const bounds = this.map.getBounds();
    console.log('Bounds de la carte:', bounds);
    
    if (bounds.isValid()) {
      console.log('Ajustement de la vue pour montrer tous les segments');
      this.map.fitBounds(bounds);
    } else {
      console.error('Les bounds ne sont pas valides');
    }
  }

}
