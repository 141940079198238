<div *ngIf="showPreferences" (click)="$event.stopPropagation()" #preferences>
    <!--<button mat-icon-button (click)="closePreferences()" class="close-button">
      <mat-icon>close</mat-icon>
    </button>-->
    <h2 class="preferences-title">{{ 'PREFERENCES' | translate }}</h2>
    <div class="preferences-content">
        <!--<div>
            <mat-form-field appearance="fill" class="custom-mat-select">
              
              <mat-select [(ngModel)]="selectedLanguage" (selectionChange)="switchLanguage($event.value)" >
                
                <mat-option value="gb">
                  <span class="fi fi-gb"></span>
                </mat-option>
                <mat-option value="fr">
                  <span class="fi fi-fr"></span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>-->
          <div class="form-group">
            <mat-form-field appearance="fill" class="custom-mat-select">
                <mat-label>{{ 'LANGUAGE' | translate }}</mat-label>
                <mat-select [(ngModel)]="selectedLanguage" (selectionChange)="switchLanguage($event.value)">
                    <mat-select-trigger>
                        <span class="flag-icon" [ngClass]="'fi fi-' + selectedLanguage"></span>
                        {{ getLanguageName(selectedLanguage) }}
                    </mat-select-trigger>

                    <mat-option value="gb">
                        <span class="fi fi-gb"></span> English
                    </mat-option>
                    <!--<mat-option value="fr">
                        <span class="fi fi-fr"></span> Français
                    </mat-option>-->
                </mat-select>
            </mat-form-field>
            
        </div>

        <div class="form-group">
            <mat-checkbox
                [(ngModel)]="advancedFeatures"
                (change)="onAdvancedFeaturesChange($event)"
                color="primary">
                {{ 'ADVANCED_FEATURES' | translate }}
            </mat-checkbox>
        </div>

        <div class="advanced-features-section" *ngIf="advancedFeatures">
            <h3>{{ 'ADVANCED_FEATURES_SECTION' | translate }}</h3>
            <div class="form-group">
                <button mat-raised-button color="primary" (click)="showAllSegments()">
                    {{ 'SHOW_ALL_SEGMENTS' | translate }}
                </button>
            </div>
            <div class="form-group">
                <button mat-raised-button color="warn" (click)="hideAllSegments()">
                    {{ 'HIDE_ALL_SEGMENTS' | translate }}
                </button>
            </div>
        </div>

        <button (click)="onLogoutClick()" class="logout-btn">{{ 'DISCONNECT' | translate }}</button>

        <!-- Bouton Fermer 
        <div class="button-container">
            <button mat-raised-button color="primary" class="close-btn" (click)="closePreferences()">{{ 'CLOSE' | translate }}</button>
        </div>
        -->
    </div>
</div>