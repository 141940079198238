/*export const environment = {
    production: false,
    apiUrl: 'http://api-dev.hyp4u.space:8080',
    wsapiUrl: 'ws://ws-dev.hyp4u.space:8081',
    BAND_DEFAULT_R:'0.6551',
    BAND_DEFAULT_G:'0.5142',
    BAND_DEFAULT_B:'0.4599'
};*/

export const environment = {
    production: false,
    apiUrl: 'http://api-dev.hyp4u.space:8080',
    wsapiUrl: 'ws://ws-dev.hyp4u.space:8081',
    BAND_DEFAULT_R:'0.6551',
    BAND_DEFAULT_G:'0.5142',
    BAND_DEFAULT_B:'0.4599'
};

