import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdvancedFeaturesService {
  private advancedFeaturesSubject = new BehaviorSubject<boolean>(false);
  private segmentsLoadedSubject = new BehaviorSubject<any>(null);
  private segmentsVisibilitySubject = new BehaviorSubject<boolean>(true);
  
  advancedFeatures$ = this.advancedFeaturesSubject.asObservable();
  segmentsLoaded$ = this.segmentsLoadedSubject.asObservable();
  segmentsVisibility$ = this.segmentsVisibilitySubject.asObservable();

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
    // Récupérer l'état initial depuis le localStorage
    const savedState = localStorage.getItem('advancedFeatures');
    if (savedState !== null) {
      this.advancedFeaturesSubject.next(JSON.parse(savedState));
    }
  }

  setAdvancedFeatures(enabled: boolean) {
    this.advancedFeaturesSubject.next(enabled);
    localStorage.setItem('advancedFeatures', JSON.stringify(enabled));
  }

  getAdvancedFeatures(): boolean {
    return this.advancedFeaturesSubject.value;
  }

  getAllSegments() {
    return this.http.get(`${this.apiUrl}/segmentation-all`, {
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    });
  }

  emitSegmentsLoaded(segments: any) {
    this.segmentsLoadedSubject.next(segments);
  }

  toggleSegmentsVisibility(visible: boolean) {
    this.segmentsVisibilitySubject.next(visible);
  }

  getSegmentsVisibility(): boolean {
    return this.segmentsVisibilitySubject.value;
  }
} 