import {OnInit, Component,  EventEmitter, Output, HostListener, ViewChild, ElementRef, Inject } from '@angular/core';
import { Appstore } from '../appstore';
import { AppstoreService } from '../appstore.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { AppWizardComponent } from '../app-wizard/app-wizard.component';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AdvancedFeaturesService } from '../advanced-features.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-preferences',
  standalone: true,
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css'],
  imports: [FormsModule,CommonModule, MatFormFieldModule, MatSelectModule, TranslateModule, MatIconModule, MatButtonModule, MatCheckboxModule],
})
export class PreferencesComponent implements OnInit {
  showPreferences = false;
  selectedLanguage = '';
  advancedFeatures = false;
  endPointSegmentationAll = 'segmentation-all';
  @Output() preferencesVisibleChange = new EventEmitter<boolean>();
  @Output() advancedFeaturesChange = new EventEmitter<boolean>();

  private apiUrl = environment.apiUrl;

  constructor(
    private translate: TranslateService, 
    private authService: AuthService, 
    private router: Router,
    private advancedFeaturesService: AdvancedFeaturesService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    const language = this.authService.getLanguage();
    console.log('preference ngoninit language' + language);
    if(language)
    {
      this.selectedLanguage = language;
    }
    
    // Initialiser l'état de la case à cocher
    this.advancedFeatures = this.advancedFeaturesService.getAdvancedFeatures();
  }
  
  togglePreferences() {
    this.showPreferences = !this.showPreferences;
    this.preferencesVisibleChange.emit(this.showPreferences);
    console.log('togglePreferences');
  }

  switchLanguage(language: string) {
    console.log('switchLanguage ' + language);
    this.translate.use(language);
    this.savePreferences(language);
  }

  getLanguageName(langCode: string): string {
    switch (langCode) {
      case 'gb':
        return 'English';
      case 'fr':
        return 'Français';
      default:
        return '';
    }
  }

  closePreferences(): void {
    this.togglePreferences();
  }

  onAdvancedFeaturesChange(event: any) {
    this.advancedFeatures = event.checked;
    this.advancedFeaturesService.setAdvancedFeatures(this.advancedFeatures);
    this.savePreferences(this.selectedLanguage);
  }

  savePreferences(language: string){
    console.log('savePreferences');
    const jsonData = JSON.stringify({
      language: language,
      advancedFeatures: this.advancedFeatures
    });

    //fetch(`http://localhost:3000/save`, {
    fetch(`${this.apiUrl}/save_user_preferences`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      credentials: 'include' as RequestCredentials,
      body: jsonData
      })
  }

  async onLogoutClick() {
    console.log('onLogoutClick');
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  showAllSegments() {
    this.advancedFeaturesService.getAllSegments().subscribe({
      next: (response) => {
        console.log('Segments récupérés:', response);
        this.advancedFeaturesService.emitSegmentsLoaded(response);
        this.advancedFeaturesService.toggleSegmentsVisibility(true);
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des segments:', error);
      }
    });
  }

  hideAllSegments() {
    this.advancedFeaturesService.toggleSegmentsVisibility(false);
  }

}
