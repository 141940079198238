import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { Observable, map, take } from 'rxjs';

@Component({
  selector: 'app-login',
  standalone: true,
  templateUrl: './login.component.html',
  imports: [FormsModule, CommonModule, MatIconModule],
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  username: string = '';
  password: string = '';
  errorMessage: string = '';
  isAuth = false;
  groups: { id: string, nom: string }[] = [];
  selectedGroup: any = null;

  constructor(private authService: AuthService, private router: Router) {
    this.authService.isAuthenticated$.subscribe(value => this.isAuth = value );
    console.log('Login component IsAuth changed');
    console.log(this.isAuth);

    if(this.isAuth)
    {
      //this.authService.restoreInfos();
    }


    this.authService.Groups$.subscribe(groupsDict => {
      console.log('this.authService.Groups$.subscribe');
      /*const simulatedGroupsDict = {
        'aa1df240-1cd3-41d3-a60c-35e449986aaf': 'G1'
      };
      this.groups = Object.entries(simulatedGroupsDict).map(([id, nom]) => ({id, nom}));*/
      this.groups = Object.entries(groupsDict).map(([id, nom]) => ({id, nom}));
      
      if (this.groups.length === 1) {

        this.selectedGroup = this.groups[0].id;
        console.log('this.authService.Groups$.subscribe 2');
        //this.onGroupSelect();
      }
    });

    console.log('groups');
    console.log(this.groups);
    if(!this.isAuth || !this.groups ||this.groups.length == 0)
    {
      
      //this.cancelLogin();
    }


  }

  async onLoginClick() {
    // La logique de votre connexion
    // Par exemple, récupérez les valeurs du formulaire et appelez votre service d'authentification
    console.log('login click');
    const username = this.username; // selon votre implémentation
    const password = this.password; // selon votre implémentation
  
    /*this.authService.login(username, password).subscribe(
      response => {
        // Gérez la réponse
        console.log('authService.login response');
        console.log(response);
        if (response.success) {
          this.authService.setAuthenticated(true); // Mettre à jour l'état d'authentification
          console.log('this.authService.authenticated');
          console.log(this.authService.isAuthenticated$);
          this.router.navigate(['/']);
          console.log('response.success');
        } else {
          // Affichez une erreur ou faites quelque chose d'autre
          console.log('login response not success');
          this.errorMessage = 'Identifiants incorrects'
        }
      }
    );*/
    //try {
      const response = await this.authService.login(username, password);
      console.log(response);
      console.log('onLoginClick Auth');
      console.log(this.isAuth);

      /*if (response.erreur) {
        console.log('login response not success');
        this.errorMessage = 'Identifiants incorrects'
      } else {
        this.authService.setAuthenticated(true);
        console.log('Token :');
        console.log(response.token);
        localStorage.setItem('jwt', response.token);
        console.log('this.authService.authenticated');
        console.log(this.authService.isAuthenticated$);
        this.router.navigate(['/']);
        console.log('response.success');
      }
    } catch (error) {
      console.error('Une autre erreur s\'est produite:', error);
    }*/


    console.log('isTokenValid');
    console.log(this.authService.isTokenValid());
    console.log('isAuth');
    console.log(this.isAuth);
    console.log('response.success');
    console.log(response.success);
    console.log('response.groups.length');
    console.log(Object.keys(response.groups).length);

    //if(this.isAuth && this.authService.isTokenValid())
    //if(this.isAuth && this.authService.isTokenValid() && this.selectedGroup)  
    if(this.isAuth && this.authService.isTokenValid() && Object.keys(response.groups).length == 1)
    {
      
      this.errorMessage = '';
      this.authService.setUserGroup(this.selectedGroup);
      this.authService.getUserInfos();
      this.router.navigate(['/']);
    }
    else if(this.isAuth && this.authService.isTokenValid() && Object.keys(response.groups).length > 1)
    {

    }
    else
    {
      this.errorMessage = 'Identifiants incorrects';
    }


  }

  onGroupSelect() {

    console.log('Groupe sélectionné :', this.selectedGroup);

    this.authService.setUserGroup(this.selectedGroup);
  
    // Redirection vers '/' après la sélection du groupe
    if(this.isAuth && this.authService.isTokenValid() && this.selectedGroup)
    {
      console.log('this.authService.getUserInfos() ongroupselect');
      this.authService.getUserInfos();
      this.router.navigate(['/']);
    }
    
  }

  cancelLogin() {
    this.authService.logout();
  }


  /*onLogout(): void {
    this.authService.logout().subscribe(
      () => {
        console.log('Logged out');
        // Ici, vous pouvez faire des actions post-déconnexion
      },
      error => {
        console.error(error);
      }
    );
  }*/
}
