export const environment = {
    production: false,
    apiUrl: 'https://api-dev.hyp4u.space:8080',
    wsapiUrl: 'wss://ws-dev.hyp4u.space:8081',
    BAND_DEFAULT_R:'0.6551',
    BAND_DEFAULT_G:'0.5142',
    BAND_DEFAULT_B:'0.4599',
    tilesUrl: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
};

/*export const environment = {*/
    /*production: false,*/
    /*apiUrl: 'http://localhost:3000',*/
    /*wsapiUrl: 'ws://localhost:8081',*/
    /*BAND_DEFAULT_R:'0.6551',*/
    /*BAND_DEFAULT_G:'0.5142',*/
    /*BAND_DEFAULT_B:'0.4599',*/
    /*tilesUrl: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'*/
    /*tilesUrl: 'file:///C:/Temp/tiles_server/{z}/{x}/{y}.png'*/
    /*tilesUrl: 'file:///%LOCALAPPDATA%/Hyp4U/Tiles/{z}/{x}/{y}.png'*/
/*};*/

