import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpErrorResponse  } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tap } from 'rxjs/operators';
import { io } from 'socket.io-client';
import { Hypercube } from './hypercube';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { fromEvent, timer } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //private socket;
  private socket!: WebSocket | null;

  private apiUrl = environment.apiUrl;
  private wsapiUrl = environment.wsapiUrl;
  

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$: Observable<boolean> = this.isAuthenticatedSubject.asObservable();
  //public isAuthenticated$: Observable<boolean> = new BehaviorSubject<boolean>(localStorage.getItem('jwt') !== null);
  
  public hypercubeStatusSubject = new BehaviorSubject<string>('');
  public hypercubeStatus$: Observable<string> = this.hypercubeStatusSubject.asObservable();

  private NodesSubject = new BehaviorSubject<any[]>([]);
  public Nodes$: Observable<any[]> = this.NodesSubject.asObservable();

  private HypercubesSubject = new BehaviorSubject<any[]>([]);
  public Hypercubes$: Observable<any[]> = this.HypercubesSubject.asObservable();

  public CurrentHypercubeSubject = new BehaviorSubject<any>(null);
  public CurrentHypercube$: Observable<any> = this.CurrentHypercubeSubject.asObservable();

  public GroupsSubject = new BehaviorSubject<any[]>([]);
  public Groups$: Observable<any[]> = this.GroupsSubject.asObservable();

  public CurrentUserGroupSubject = new BehaviorSubject<any>(null);
  public CurrentUserGroup$: Observable<any> = this.GroupsSubject.asObservable();

  @Output() logoutEvent = new EventEmitter<any>();

  @Output() errorEvent = new EventEmitter<any>();


  constructor(private http: HttpClient, private jwtHelper: JwtHelperService, private router: Router, private translate: TranslateService) { 
    console.log('AuthService constructor called', 'Timestamp:', new Date().toISOString());
    console.log('this.hypercubeStatusSubject.next(loading)');
    this.hypercubeStatusSubject.next('loading');
    
    //this.hypercubeStatusSubject.next('ok');
    const token = localStorage.getItem('token');
    const default_language = 'gb';
    const language = localStorage.getItem('language');
    const group = localStorage.getItem('group');
    if(group)
    {
      this.CurrentUserGroupSubject.next(group);
    }

    if(language && language != 'null'){
      this.translate.use(language.toLowerCase());
    }
    else {
      this.translate.use(default_language);
    }
    //if (token && !this.jwtHelper.isTokenExpired(token)) {
    if (token && !this.jwtHelper.isTokenExpired(token) && group) {
      this.isAuthenticatedSubject.next(true);
      console.log('request userinfos');
      this.getUserInfos();
    }

    
  }

  /*login(username: string, password: string): Observable<any> {
    console.log('Login : ' + `${this.apiUrl}/login`);
    return this.http.post(`${this.apiUrl}/login`, { username, password });
  }*/


  public sendMessage(message: string): void {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('Socket non connecté');
    }
  }

  public close(): void {
    if (this.socket) {
      this.socket.close();
    }
  }

  async login(username: string, password: string): Promise<any> {
    try {
      this.translate.setDefaultLang('gb');
      localStorage.removeItem('token');
      const response : any = await this.http.post(`${this.apiUrl}/login`, { username, password }, {withCredentials: true}).toPromise();
      console.log('login response : ');
      console.log(response);
      if (response && response.token) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('userid', response.IdUser);
        localStorage.setItem('username', response.username);
        localStorage.setItem('language', response.language);
        localStorage.setItem('groups', JSON.stringify(response.groups));
        const language = localStorage.getItem('language');
        if(language && language != 'null')
          this.translate.use(language.toLowerCase());
        console.log('Language : ' + language);
        console.log(localStorage.getItem('token'));
        console.log('Authenticated');

        console.log('nodes from backend');
        console.log(response.nodes);
        //this.NodesSubject.next(response.nodes);


        
        
        this.isAuthenticatedSubject.next(true);

        console.log('Login socket state : ' + this.socket);

        //

        //const socket = io(this.apiUrl, {
/*        this.socket = io(this.apiUrl, {
          transports : ['websocket'],
          query: { token: localStorage.getItem('token') }
        });

        this.socket.on('connect', () => {
          console.log('Connected to the server');
        });

        this.socket.on('disconnect', () => {
          console.log('Disconnected from the server');
        });

        this.socket.on('error', (error) => {
          console.log('Socket Error:', error);
        });

        this.socket.on('message', (data) => {

          console.log('Message received for ' + data.userId);
          if (data.userId === this.getUsername()) {
            console.log('Message received:', data);
            if ((data.payload.hypercube_status !== this.hypercubeStatusSubject.getValue())) {
              console.log('old value : ' + this.hypercubeStatusSubject.getValue());
              console.log('new value : ' + data.payload.hypercube_status);
              this.hypercubeStatusSubject.next(data.payload.hypercube_status);
            }
          }
          else
          {
            return;
          }
        });*/
        
        //this.socket = new WebSocket(this.wsapiUrl);

        






        console.log('this.hypercubeStatusSubject.next(loading) from login');
        this.hypercubeStatusSubject.next('loading');
        

        console.log('Groups');
        console.log(response.groups);
        this.GroupsSubject.next(response.groups);

        //this.getUserInfos();
      }
      return response;
    } catch (error) {

      if (error instanceof HttpErrorResponse && error.status === 401) {

        return {erreur: 'Non autorisé', detail: error};
      }

      throw error;
    }
  }

  /*login(username: string, password: string): Observable<any> {
    console.log('AuthService login');
    return this.http.post(`${this.apiUrl}/login`, { username, password }, {withCredentials: true}).pipe(
      tap((response: any) => {
        console.log('tap');
        if (response && response.token) {
          localStorage.setItem('token', response.token);
          console.log('Authenticated');
          this.isAuthenticatedSubject.next(true);
        }
      })
    );
  }*/

  /*async login(username: string, password: string): Promise<any> {
    return this.http.post(`${this.apiUrl}/login`, { username, password }).pipe(
      tap((response: any) => {
        if (response && response.token) {
          localStorage.setItem('token', response.token);
          this.isAuthenticatedSubject.next(true);
        }
      })
    );
  }*/

 /* async getUserInfos(){
    console.log('get user infos from auth service');
    console.log(localStorage.getItem('token'));

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  
      },
      credentials: 'include' as RequestCredentials  
    };

    fetch(`${this.apiUrl}/userinfos`, options)
    .then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          // Gérez l'erreur 401 ici. Par exemple, redirigez vers une page de connexion
          console.log('Error 401 handled');
          this.router.navigate(['/login']);
        } else {
          // Gérez d'autres codes d'erreur HTTP ici si nécessaire
          this.router.navigate(['/login']);
        }
      }
      return response.json();
    })
    .then(buffer => {
      console.log('userinfos');
      console.log(buffer);
      this.NodesSubject.next(buffer.nodes);
      console.log('Default hypercube :');
      console.log(buffer.defaultHypercube);
      this.CurrentHypercubeSubject.next(buffer.defaultHypercube);
      let hcs = buffer.hypercubes.map(item => ({
        id: item.id,
        name: item.name,
        country: item.country,
        thumbnail: item.thumbnail,
        latitude: item.latitude,
        longitude: item.longitude,
        default_bands: item.default_bands,
        bands: item.bands,
        border: item.border
      }))

      
      const url = `${this.wsapiUrl}?token=${localStorage.getItem('token')}`;
        this.socket = new WebSocket(url);
        

        this.socket.onopen = (event) => {
          console.log('Connexion WebSocket ouverte', event);
          this.sendMessage('Salut serveur!');
        };

        this.socket.onmessage = (event) => {
          console.log('Message reçu:', event.data);
          //console.log(JSON.parse(event.data));
          const data = JSON.parse(event.data);
          if (data.userId === this.getUsername()) {
            //console.log('Message received:', data);
            if ((data.payload.hypercube_status !== this.hypercubeStatusSubject.getValue())) {
              console.log('old value : ' + this.hypercubeStatusSubject.getValue());
              console.log('new value : ' + data.payload.hypercube_status);
              this.hypercubeStatusSubject.next(data.payload.hypercube_status);
            }
          }
          else
          {
            return;
          }

        };

        this.socket.onclose = (event) => {
          console.log('Connexion WebSocket fermée', event);
        };

        this.socket.onerror = (error) => {
          console.error('Erreur WebSocket:', error);
        };

      console.log('HCS :');
      console.log(hcs);

      this.HypercubesSubject.next(hcs);
    })
    .catch(error => {
      console.error('Il y a eu un problème avec l\'opération fetch:', error.message);
    });

  }*/

  async getUserInfos(){
    console.log('get user infos from auth service');
    console.log(localStorage.getItem('token'));
    console.log(localStorage.getItem('group'));
    let groupId = localStorage.getItem('group')

    let jsonData = JSON.stringify({"groupId": groupId});

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  
      },
      credentials: 'include' as RequestCredentials, 
      body: jsonData
    };

    fetch(`${this.apiUrl}/userinfos`, options)
    .then(response => {
      if (!response.ok) {
        if (response.status === 401) {
          // Gérez l'erreur 401 ici. Par exemple, redirigez vers une page de connexion
          console.log('Error 401 handled');
          this.setAuthenticated(false);
          this.router.navigate(['/login']);
        } else {
          // Gérez d'autres codes d'erreur HTTP ici si nécessaire
          this.router.navigate(['/login']);
        }
      }
      return response.json();
    })
    .then(buffer => {
      console.log('userinfos');
      console.log(buffer);
      this.NodesSubject.next(buffer.nodes);
      console.log('Default hypercube :');
      console.log(buffer.defaultHypercube);
      this.CurrentHypercubeSubject.next(buffer.defaultHypercube);

      /*let hcs = buffer.hypercubes.map(item => ({
        id: item.id,
        name: item.name,
        country: item.country,
        thumbnail: item.thumbnail,
        latitude: item.latitude,
        longitude: item.longitude,
        default_bands: item.default_bands,
        bands: item.bands,
        border: item.border
      }))*/

      let hcs = buffer.portfolio.map(item => ({
        id: item.IdImageFile,
        IdImageFile: item.IdImageFile,
        name: item.name,
        //country: item.country,
        country: item.Localisation,
        thumbnail: item.thumbnail,
        Border: item.Border
        //polygon: item.JSONPolygon,
        //centralpoint: item.JSONCentralPoint,
      }))

      console.log('this.socket : ');
      console.log(this.socket);
      if(this.socket == undefined || this.socket.readyState !== WebSocket.OPEN)
      {
        const url = `${this.wsapiUrl}?token=${localStorage.getItem('token')}`;
        this.socket = new WebSocket(url);

        this.socket.onopen = (event) => {
          console.log('Connexion WebSocket ouverte', event);
          
          //this.sendMessage('Salut serveur!');
        };

        this.socket.onmessage = (event) => {
 
          console.log('Message reçu:', event.data, 'Timestamp:', new Date().toISOString() );
          //console.log(JSON.parse(event.data));
          const data = JSON.parse(event.data);
          //if (data.userId === this.getUsername()) {
          //console.log('data.userId : '  +  data.userId);
          //console.log('this.getId() : '  +  this.getId());
          if (data.userId === this.getId()) {
            //console.log('Message received:', data);
            if ((data.payload.hypercube_status !== this.hypercubeStatusSubject.getValue())) {
              console.log('old value : ' + this.hypercubeStatusSubject.getValue());
              console.log('new value : ' + data.payload.hypercube_status);
              console.log('this.hypercubeStatusSubject.next('+data.payload.hypercube_status+')');
              this.hypercubeStatusSubject.next(data.payload.hypercube_status);
              
            }
          }
          else
          {
            return;
          }

        };

        this.socket.onclose = (event) => {
          console.log('Connexion WebSocket fermée', event);
          /*if (!event.wasClean) {
            this.socket = new WebSocket(url);
          }*/
        };

        this.socket.onerror = (error) => {
          console.error('Erreur WebSocket:', error);
        };
      }

      console.log('HCS :');
      console.log(hcs);

      this.HypercubesSubject.next(hcs);
    })
    .catch(error => {
      console.error('Il y a eu un problème avec l\'opération fetch:', error.message);
    });

  }

  setAuthenticated(isAuthenticated: boolean): void {
    console.log('setAuthenticated , valeur : ');
    console.log(isAuthenticated);
    this.isAuthenticatedSubject.next(isAuthenticated);
  }

  setUserGroup(groupId: string): void {
    console.log('setUserGroup , valeur : ');
    console.log(groupId);
    localStorage.setItem('group', groupId);
    this.CurrentUserGroupSubject.next(groupId);
  }

  restoreInfos(){
    let groups = localStorage.getItem('groups');
    if(groups)
    {
      console.log(JSON.parse(groups));
      console.log('restoreInfos');
      this.GroupsSubject.next(JSON.parse(groups));
    }
    
  }

  logout(): void {
    console.log('logging out');
    
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')  // Assurez-vous que le préfixe "Bearer " est correct.
      },
      credentials: 'include' as RequestCredentials  // Important si vous utilisez des sessions ou des cookies.
    };

    fetch(`${this.apiUrl}/logout`, options)
      .then(response => response.json())
      .then(buffer => {
      });
    this.NodesSubject.next([]);
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    this.isAuthenticatedSubject.next(false);
    this.CurrentHypercubeSubject.next(null);
    console.log('this.hypercubeStatusSubject.next()');
    this.hypercubeStatusSubject.next('');
    
    if(this.socket)
    {
      //this.socket.disconnect();
      console.log('closing socket');
      this.socket.onopen = null;
      this.socket.onmessage = null;
      this.socket.onerror = null;
      this.socket.onclose = null;
      this.socket.close();
      this.socket = null;
    }
    this.logoutEvent.emit();
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  getLanguage(): string | null {
    return localStorage.getItem('language');
  }

  getUsername(): string | null {
    return localStorage.getItem('username');
  }

  getId(): string | null {
    return localStorage.getItem('userid');
  }

  getNodes(): string | null {
    return localStorage.getItem('nodes');
  }

  isTokenValid(): boolean {
    const token = this.getToken();  // Suppose que vous avez une méthode pour récupérer le token
    if (!token) {
      return false;
    }
    return !this.jwtHelper.isTokenExpired(token);
  }

  async loadHypercube(hypercube: Hypercube): Promise<any> {

    this.CurrentHypercubeSubject.next(hypercube);
    let jsonData = JSON.stringify({"id": hypercube.id});
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token') 
      },
      credentials: 'include' as RequestCredentials,  
      body: jsonData
    };

    //let response = await fetch(`${this.apiUrl}/loadhypercube`, options);

    //let response = fetch(`${this.apiUrl}/loadhypercube`, options);

    fetch(`${this.apiUrl}/loadhypercube`, options)
    .then(response => {
      if (!response.ok) {
        //throw new Error('Server error while loading hypercube'); 
        this.errorEvent.emit('Server error while loading hypercube');
      }
      return response.json();
    })
    .then(data => {

    })
    .catch(err => {
      console.log(err.message);
    });


    //console.log(hypercube.bands);
    
    //return true;
  }

}
