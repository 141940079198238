import { OnInit, AfterViewInit, Component,  EventEmitter, Input, Output, HostListener, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { Rendezvousdata } from '../rendezvousdata';
import { Orderdata } from '../orderdata';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { CommonModule } from '@angular/common';
import { MapService } from '../map.service';
import { take } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { CartService } from '../cart.service';
import { Subscription, combineLatest } from 'rxjs';
import { MatTabsModule, MatTabChangeEvent} from '@angular/material/tabs';
import { environment } from '../../environments/environment';
import { Catalogdata } from '../catalogdata';

var ELEMENT_DATA: Catalogdata[] = [

];

var ORDERS_DATA: Orderdata[] = [

];

@Component({
  selector: 'app-catalog',
  standalone: true,
  imports: [ MatTableModule, MatSortModule, CommonModule, MatIconModule, FormsModule, MatSelectModule, MatTabsModule ],
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.css']
})
export class CatalogComponent implements OnInit, AfterViewInit{
  @Output() RendezVousVisibleChange = new EventEmitter<boolean>();
  @Output() geoPointChange = new EventEmitter<{latitude: number, longitude: number} | null>();
  @Output() rendezvousfootprintChange = new EventEmitter<string | null>();
  displayedColumns: string[] = ['Orbite', 'Distance', 'Rollangle', 'acquisitionDate'] ;
  //displayedColumns: string[] = ['orbite'];
  showCatalog = false;
  showSettings = false;
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  orderDataSource = new MatTableDataSource(ORDERS_DATA);
  isLoading = false;
  sortColumn = 'orbite'; // La colonne de tri initiale
  sortDirection = 'asc'; // La direction de tri initiale

  @ViewChild('catalogContainer') catalogContainer!: ElementRef;
  containerWidth!: number;
  settingsPanelLeft!: number;
  
  public geoPoint: { latitude: number, longitude: number } | null = null;
  @Input()
  get geoPointInput(): { latitude: number; longitude: number } | null {
    return this.geoPoint;
  }

  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  selectedItem: any = null;
  selectedOption: string| null = 'catalogVersion';
  hoveredItemRef: any | null = null;

  newItem = { id: 0, catalogueid: 0, name: '', quantity: 1, price: 0, lat: 0, long: 0, acquisitiondate: new Date(), rollangle: 0, orbite: 0, type: 'Image catalog', localisation: '', level: '', buytype: this.selectedOption};

  private ROIPointSubscription!: Subscription;

  private apiUrl = environment.apiUrl;
  private endPointGetCatalog = 'getcatalog';
  private endPointGetProcessLevels = 'processlevels';

  public removeButtonVisible = false;

  processLevels!: string[];

  constructor(private mapService: MapService, private cartService: CartService) { 

  }

  ngOnInit() {
    this.getProcessLevels() ;
    this.dataSource.sort = this.sort;

    this.ROIPointSubscription = this.mapService.getROIPoint().subscribe({
      next: (point) => {
        if (point) {
          this.onROIPointChange(point);
          this.removeButtonVisible = true;
        }
        else{
          this.removeButtonVisible = false;
          this.dataSource.data = [];
          this.geoPoint = null;
        }
      },
      error: (err) => console.error(err),
    });
  }

  ngOnDestroy() {
    this.ROIPointSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    const containerRect = this.catalogContainer.nativeElement.getBoundingClientRect();
    this.settingsPanelLeft = containerRect.right;
    console.log('containerRect.right : ' + containerRect.right);
  }

  
  toggleCatalog() {
    console.log('toggleRendezVous');
    this.showCatalog = !this.showCatalog;
    this.RendezVousVisibleChange.emit(this.showCatalog);
  }

  displayRendezVous() {
    if(!this.showCatalog)
    {
      this.toggleCatalog();
    }
  }

  addDataFromJson(jsonData: string) {
    this.dataSource.data = [];
    ELEMENT_DATA = [];
    const dataObjects = JSON.parse(jsonData);
    const newData: Catalogdata[] = dataObjects.map((item: any) => ({
      id: item.ImageFileId,
      catalogueId: item.CatalogueId,
      datetime: new Date(item.Datetime).toLocaleDateString('fr-FR'),
      product: item.Product,
      level: item.Level,
      lat: item.Lat,
      lon: item.Lon,
      localisation: item.Localisation,
      centralpoint: item.Centralpoint,
      thumbnail: item.Thumbnail,
      distanceKm: item.DistanceKm,
      cloudcover: item.Cloudcover,
      footprint: item.FootPrint
    }));
    ELEMENT_DATA.push(...newData);
    this.dataSource.data = [...ELEMENT_DATA];
    this.isLoading = false;
    console.log('this.isLoading');
    console.log(this.isLoading);
  }

  addOrdersFromJson(jsonData: any[]) { 
    this.orderDataSource.data = [];
    ORDERS_DATA = [];
    console.log('addOrdersFromJson');
    console.log(jsonData);
    //const dataObjects = JSON.parse(jsonData);
    const dataObjects = jsonData;
    const newData: Orderdata[] = dataObjects
    .filter((item: any) => item.RdvId === null)
    .map((item: any) => ({
      OrderId: item.OrderId,
      OrderDetailId: item.OrderDetailId,
      DateTime: item.DateTime,
      Statut: item.Statut,
      ImageCatId: item.ImageCatId,
      ImageRTId: item.ImageRTId,
      ImageTCId:  item.ImageTCId,
      RdvId: item.RdvId,
      OrderImageFileId: item.OrderImageFileId,
      OrderType: item.OrderType,
      JsonFootPrint: item.JsonFootPrint,
      RdvDateTimeRecord: item.RdvDateTimeRecord,
      RdvDateTime: item.RdvDateTime
    }));

    ORDERS_DATA.push(...newData);
    this.orderDataSource.data = [...ORDERS_DATA];
    this.isLoading = false;
    console.log('this.isLoading');
    console.log(this.isLoading);
  }

  setLoading(loading: boolean){
    this.isLoading = loading;
  }

  updateGeoPoint(latitude: number, longitude: number) {
    this.geoPoint = { latitude, longitude };
    this.geoPointChange.emit(this.geoPoint);
  }

  createGeoPoint() {
    this.mapService.getCenter().pipe(take(1)).subscribe(center => {
      console.log('Create Geopoint center : ' + center.latitude + ' ' + center.longitude);
      this.updateGeoPoint(center.latitude, center.longitude);
    });
  }

  createGeoPointWithCoordinates(coordinates: L.LatLng) {
    console.log('Create Geopoint center : ' + coordinates.lat + ' ' + coordinates.lng);
    this.updateGeoPoint(coordinates.lat, coordinates.lng);
    //this.displayRendezVous();
  }

  removeGeoPoint() {
    console.log('removing geopoint');
    this.dataSource.data = [];
    this.geoPoint = null;
    console.log(this.geoPoint);
    this.geoPointChange.emit(this.geoPoint);
  }

  createROIPoint() {
    this.mapService.createROIPoint();
  }

  removeROIPoint() {
    console.log('removing roipoint');
    //this.dataSource.data = [];
    //this.geoPoint = null;
    console.log(this.geoPoint);
    this.mapService.removeROIPoint();
    //this.geoPointChange.emit(this.geoPoint);
  }

  onCatalogClick(item: object){
    console.log('onCatalogClick');
    let footprint = null;
    if (this.selectedItem === item) {
      this.selectedItem = null;
    } else {
      this.selectedItem = item;
      footprint = this.selectedItem.footprint;
    }
    //this.rendezvousfootprintChange.emit(footprint);
  }

  onItemHover(item: any) {
    if(this.hoveredItemRef != item)
    {
      this.hoveredItemRef = item;
      console.log('onItemHover');
      console.log(this.hoveredItemRef.footprint);
      this.rendezvousfootprintChange.emit(this.hoveredItemRef.footprint);
    }

  }

  onOrderItemHover(item: any) {
    if(this.hoveredItemRef != item)
    {
      this.hoveredItemRef = item;
      console.log('onOrderItemHover');
      console.log(this.hoveredItemRef);
      console.log(this.hoveredItemRef.JsonFootPrint);
      this.rendezvousfootprintChange.emit(this.hoveredItemRef.JsonFootPrint);
    }

  }

  onItemLeave() {
    this.hoveredItemRef = null;
    this.rendezvousfootprintChange.emit(null);
  }

  toggleSettings() {
    this.showSettings = !this.showSettings;
  }

  toggleSortDirection() {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.sortData(); 
  }

  sortData() {
    this.dataSource.data.sort((a, b) => {
      let valueA = a[this.sortColumn.toLowerCase()];
      let valueB = b[this.sortColumn.toLowerCase()];

      // Convertir en nombres si ce sont des nombres
      if (!isNaN(+valueA) && !isNaN(+valueB)) {
        valueA = +valueA;
        valueB = +valueB;
      }

      if (valueA < valueB) {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return this.sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  backToList(): void {
    this.selectedItem = null;
  }

  addItemToCart() {
      console.log('addItemToCart');
      console.log(this.selectedItem);
      this.newItem = {id: this.selectedItem.id , catalogueid: this.selectedItem.catalogueId , name: this.selectedItem.acquisitiondate, quantity: 1, price: 0, lat: this.selectedItem.lat, long: this.selectedItem.long, acquisitiondate: this.selectedItem.acquisitiondate, rollangle: this.selectedItem.rollangle, orbite: this.selectedItem.orbite, type: 'Image Catalog', localisation: this.selectedItem.localisation, level: this.selectedItem.level, buytype: this.selectedOption};
      if(!this.cartService.itemIsInCart(this.newItem))
      {
        this.cartService.addItem({ ...this.newItem });
      }
      
      this.backToList();
  }

  onTabChanged(event: MatTabChangeEvent): void {
    if (event.index === 1) { 
      console.log('onTabChanged');
      this.cartService.getOrders().then(orders => {
        console.log(orders);
        this.addOrdersFromJson(orders);
      }).catch(error => {
        console.error('Erreur lors de la récupération des commandes:', error);
      });
    }
  }

  onROIPointChange(point: { latitude: number, longitude: number }) {
    this.setLoading(true);

    let parameters = {
      lat: point.latitude,
      long: point.longitude
    };

    fetch(`${this.apiUrl}/${this.endPointGetCatalog}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        credentials: 'include' as RequestCredentials,
        body: JSON.stringify(parameters),
        })
      .then(response => response.json())
      .then((data) => {
        console.log('backend response ' + JSON.stringify(data));
        this.addDataFromJson(JSON.stringify(data));
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  }

  getProcessLevels() {
    fetch(`${this.apiUrl}/${this.endPointGetProcessLevels}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      credentials: 'include' as RequestCredentials,
      body: '',
      })
    .then(response => response.json())
    .then((data) => {
      console.log('backend response ' + JSON.stringify(data));
      this.addProcessLevelsFromJson(JSON.stringify(data));
    })
    .catch((error) => {
        console.error('Error:', error);
    });
  }

  addProcessLevelsFromJson(jsonData: string) { 
    this.processLevels = [];
    console.log('addProcessLevelsFromJson');
    console.log(jsonData);
    const dataObjects = JSON.parse(jsonData);

    dataObjects.forEach(item => {
      this.processLevels.push(item.DeliveryLevel.trim()); 
    });

  }

}
