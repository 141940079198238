import { OnInit, Component,  EventEmitter, Output, HostListener, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Appstore } from '../appstore';
import { AppstoreService } from '../appstore.service';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { AppWizardComponent } from '../app-wizard/app-wizard.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth.service';
import { Subscription, combineLatest } from 'rxjs';
import { filter, max, pairwise  } from 'rxjs/operators';

@Component({
  selector: 'app-appstore',
  standalone: true,
  imports: [FormsModule, CommonModule,MatTabsModule,MatIconModule, TranslateModule ],
  templateUrl: './appstore.component.html',
  styleUrls: ['./appstore.component.css']
})
export class AppstoreComponent implements OnInit{
  @Output() appStoreVisibleChange = new EventEmitter<boolean>();
  apps: Appstore[] = [];
  ownedApps: Appstore[] = [];
  availableApps: Appstore[] = [];
  showAppStore = false;
  searchQuery = '';
  @ViewChild('appStore') appStore!: ElementRef;

  @Output() buttonClicked = new EventEmitter<any>();
  @Output() mapClicked = new EventEmitter<any>();

  private apiUrl = environment.apiUrl;
  private endPointApps = 'getapps';

  private hypercubeStatusSubscription!: Subscription;

  async ngOnInit() {
      //this.apps =  await this.appService.getApps();
      
      /*fetch(`${this.apiUrl}/${this.endPointApps}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        credentials: 'include' as RequestCredentials
        })
      .then(response => response.json())
      .then((data) => {
        console.log('backend response ' + JSON.stringify(data));
        this.apps =  data;
        this.filterApps();
      })
      .catch((error) => {
          console.error('Error:', error);
      });*/

          this.hypercubeStatusSubscription = this.authService.hypercubeStatusSubject.pipe(
            pairwise(),
            filter(([previousValue, currentValue]) => previousValue === 'loading' && currentValue === 'ok'),
            //filter(([previousValue, currentValue]) => (previousValue === 'loading' || previousValue === 'none') && currentValue === 'ok'),
          ).subscribe(() => {
              console.log("La valeur est passée de 'loading' à 'ok'", 'Timestamp:', new Date().toISOString());
              if(this.authService.CurrentHypercubeSubject.value !== null)
              {
                fetch(`${this.apiUrl}/${this.endPointApps}`, {
                  method: 'GET',
                  headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + localStorage.getItem('token')
                  },
                  credentials: 'include' as RequestCredentials
                  })
                .then(response => response.json())
                .then((data) => {
                  console.log('backend response ' + JSON.stringify(data));
                  this.apps =  data;
                  this.filterApps();
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
              }

          });
    

  }

  constructor(private appService: AppstoreService, private dialog: MatDialog, private translate: TranslateService, private authService: AuthService) {
    //this.apps =  this.appService.getApps();
    //this.filterApps();
  }

  onButtonClick(app: any): void {
    console.log('onButtonClick');
    console.log(app.values);
    if(app.values)
    {
      this.buttonClicked.emit(app);
    }
    
    /*if(app.params)
    {
      const dialogRef = this.dialog.open(AppWizardComponent, {
        width: '400px',  // Ajustez la largeur selon vos besoins
        data: app
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) { 
          console.log('Données renvoyées depuis le Wizard:', result);

          app.paramsresults = result;
          this.buttonClicked.emit(app);
        }
      });
    }
    else{
      this.buttonClicked.emit(app);
    }*/
    
    
  }

  onLabelClick(app: any): void {
    console.log('onLabelClick');
    console.log(app.values);
    if(!app.values)
    {
      this.buttonClicked.emit(app);
    }
  }

  filterApps() {
    this.ownedApps = this.apps.filter(app => app.owned);
    this.availableApps = this.apps.filter(app => !app.owned);
  }

  toggleAppStore() {
    this.showAppStore = !this.showAppStore;
    this.appStoreVisibleChange.emit(this.showAppStore);
    console.log('toggleAppStore');
  }

  onSearch() {
    if (!this.searchQuery) {
      this.filterApps();
    } else {
      const lowerCaseQuery = this.searchQuery.toLowerCase();
      this.ownedApps = this.apps.filter(app => app.owned && app.name.toLowerCase().includes(lowerCaseQuery));
      this.availableApps = this.apps.filter(app => !app.owned && app.name.toLowerCase().includes(lowerCaseQuery));
    }
  }

  getSelectedValueInfo(app: Appstore): string {
    console.log('getSelectedValueInfo');
    const selectedInfo = app.valuesInfo?.find(item => item.id === app.selectedValue);
    console.log('selectedInfo');
    console.log(selectedInfo);
    console.log('selectedInfo.infoHTML');
    console.log(selectedInfo ? selectedInfo.infoHTML : 'selectedInfo null');
    return selectedInfo ? selectedInfo.infoHTML : '';
  }


  openInfoModal(app: Appstore) {

    const htmlContent = this.getSelectedValueInfo(app);

    console.log('htmlContent');
    console.log(htmlContent);

    if(htmlContent)
    {
      const dialogRef = this.dialog.open(InfoDialogComponent, {
        width: '400px',
        data: { htmlContent: htmlContent }
      });
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if(this.showAppStore && this.appStore && !this.appStore.nativeElement.contains(event.target))
    {
      console.log(event.target);
      const item = event.target as HTMLElement;
      if(item.id === "map")
      {
        console.log('Click on map : close appStore');
        //this.toggleAppStore();
        this.mapClicked.emit();
      }
    }
  }
}
