import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartService } from '../cart.service';
import { Subscription } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'app-cart',
  standalone: true,
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  imports: [CommonModule, MatIconModule ],
})
export class CartComponent implements OnInit {
  showCart = false;
  items$ = this.cartService.items$;
  @Output() cartVisibleChange = new EventEmitter<boolean>();
  private itemsSubscription!: Subscription;

  constructor(private cartService: CartService) { }

  ngOnInit(): void {
  }
  
  ngOnDestroy() {
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }
  }

  toggleCart() {
    this.showCart = !this.showCart;
    this.cartVisibleChange.emit(this.showCart);
    console.log('toggleCart');
  }

  removeItem(item) {
    this.cartService.removeItem(item);
  }

  getTotal() {
    return this.cartService.getTotal();
  }


  orderItems() {
    console.log('orderItems');
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }
    /*this.items$.subscribe(items => {
      items.map(item => this.cartService.orderItems(item));
      
    });*/
    this.itemsSubscription = this.cartService.items$.subscribe(items => {
      // Assuming getItems() is the method that returns the Observable<CartItem[]>
      // and orderItems(item) is the method to process each item
      items.map(item => this.cartService.orderItems(item));
    });
    if (this.itemsSubscription) {
      this.itemsSubscription.unsubscribe();
    }
  }

}
