<div *ngIf="showCart" class="panier-container">
    <h2>Cart</h2>
    <ul>
      <li *ngFor="let item of items$ | async">
        <!--<div>Orbite : <span class="data-value">{{ item.orbite }}</span></div>
        <div>Acquisition Date : <span class="data-value">{{ item.acquisitiondate }}</span></div>-->

        <!-- Bloc pour 'Rendez-vous' -->
        <ng-container *ngIf="item.type === 'Rendez-vous'; else imageCatalogBlock">
          <div>Type : <span class="data-value">{{ item.type }}</span></div>
          <div>Orbite : <span class="data-value">{{ item.orbite }}</span></div>
          <div>Acquisition Date : <span class="data-value">{{ item.acquisitiondate | date:'MM/dd/yyyy' }}</span></div>
        </ng-container>
        
        <!-- Bloc alternatif pour 'Image Catalog' -->
        <ng-template #imageCatalogBlock>
          <div>Type : <span class="data-value">{{ item.type }}</span></div>
          <div>Localisation : <span class="data-value">{{ item.localisation }}</span></div>
          <div>Level : <span class="data-value">{{ item.level }}</span></div>
          <div>Buy type : <span class="data-value">{{ item.buytype }}</span></div>
        </ng-template>
      
        <button class="deleteButton" (click)="removeItem(item)">
          <mat-icon>delete</mat-icon>
        </button>
      </li>
    </ul>
    <div class="total">
      Total: {{ getTotal()  }}
    </div>
    <div class="button-container">
      <button class="orderButton" [disabled]="!(items$ | async)?.length" (click)="orderItems()">Order</button>
    </div>
</div>